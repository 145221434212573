import { createSelector } from 'reselect';

const getCurrentState = state => {
    if (state) {
        return state;
    }
};
const getOrganization = state => state.organization;

export const getSiteList = createSelector(getCurrentState, getOrganization, (state, org) => {
    const match = state.gqlData.orgs.find(item => item.group.groupId === org.groupId);
    const siteList = match.childGroups.siteList;

    return siteList;
});
