import React from 'react';
import style from './style.css';
import Tooltip from 'cccisd-tooltip';

const ChartSection = ({ sections, data }) => {
    return (
        <>
            {sections.map((section, index) => {
                return (
                    <div className={style.section} key={index}>
                        <div className={style.label} style={{ backgroundColor: section.color }}>
                            {section.section}
                        </div>
                        {section.subSections ? (
                            <div className={style.subBars}>
                                {section.subSections.map((subsec, id) => {
                                    return (
                                        <div className={style.subSection} key={id}>
                                            <div className={style.subSectionLabel}>{subsec.section}</div>
                                            <div className={style.subSectionBars}>
                                                {subsec.bars.map((bar, i) => {
                                                    const evalTag = bar.evalTag;
                                                    const n = data[evalTag + 'N'];
                                                    const sum = data[evalTag + 'SUM'];
                                                    const value = n === 0 ? 0 : (sum / n) * 100;
                                                    const rounded = Math.round(value * 10) / 10;

                                                    return (
                                                        <div style={{ display: 'flex' }} key={i}>
                                                            {bar.tooltip ? (
                                                                <div className={style.subBarLabel}>
                                                                    <Tooltip title={bar.tooltip}>
                                                                        {bar.description}
                                                                    </Tooltip>
                                                                </div>
                                                            ) : (
                                                                <div className={style.subBarLabel}>
                                                                    {bar.description}
                                                                </div>
                                                            )}
                                                            {rounded >= 4 ? (
                                                                <div
                                                                    className={style.chartBar}
                                                                    style={{
                                                                        width: `${0.85 * rounded}%`,
                                                                        backgroundColor: section.barColor,
                                                                        textAlign: 'right',
                                                                        paddingRight: rounded !== 0 && '0.5em',
                                                                        color: '#fff',
                                                                    }}
                                                                >
                                                                    {rounded !== 0 && rounded + '%'}
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <div
                                                                        className={style.chartBar}
                                                                        style={{
                                                                            width: `${0.85 * rounded}%`,
                                                                            backgroundColor: section.barColor,
                                                                            textAlign: 'right',
                                                                            paddingRight: rounded !== 0 && '0.5em',
                                                                            color: '#fff',
                                                                        }}
                                                                    />
                                                                    {rounded === 0 ? (
                                                                        ` 0%`
                                                                    ) : (
                                                                        <span
                                                                            style={{
                                                                                marginLeft: '5px',
                                                                            }}
                                                                        >
                                                                            {rounded}%
                                                                        </span>
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <div className={style.bars}>
                                <>
                                    {section.bars.map((bar, i) => {
                                        const evalTag = bar.evalTag;
                                        const n = data[evalTag + 'N'];
                                        const sum = data[evalTag + 'SUM'];
                                        const value = n === 0 ? 0 : (sum / n) * 100;
                                        const rounded = Math.round(value * 10) / 10;
                                        return (
                                            <div style={{ display: 'flex' }} key={i}>
                                                {bar.tooltip ? (
                                                    <div className={style.barLabel}>
                                                        <Tooltip title={bar.tooltip}>{bar.description}</Tooltip>
                                                    </div>
                                                ) : (
                                                    <div className={style.barLabel}>{bar.description}</div>
                                                )}
                                                {rounded >= 4 ? (
                                                    <div
                                                        className={style.chartBar}
                                                        style={{
                                                            width: `${0.85 * rounded}%`,
                                                            backgroundColor: section.barColor,
                                                            textAlign: 'right',
                                                            paddingRight: rounded !== 0 && '0.5em',
                                                            color: '#fff',
                                                        }}
                                                    >
                                                        {rounded !== 0 && rounded + '%'}
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div
                                                            className={style.chartBar}
                                                            style={{
                                                                width: `${0.85 * rounded}%`,
                                                                backgroundColor: section.barColor,
                                                                textAlign: 'right',
                                                                paddingRight: rounded !== 0 && '0.5em',
                                                                color: '#fff',
                                                            }}
                                                        />
                                                        {rounded === 0 ? (
                                                            ` 0%`
                                                        ) : (
                                                            <span style={{ marginLeft: '5px' }}>{rounded}%</span>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        );
                                    })}
                                </>
                            </div>
                        )}
                    </div>
                );
            })}
        </>
    );
};

export default ChartSection;
