import React, { useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    initializeFilters,
    resetWidgetsLoaded,
    setAssignmentProgressIds,
    setFilterHack,
    setAssignmentCount,
} from 'js/reducers/report.js';
import Component from './component.js';
import style from './style.css';
import Loader from 'cccisd-loader';

const DashPlayer = props => {
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            await dispatch(initializeFilters());
        })();
    }, []);
    const resetLoading = () => {
        props.resetWidgetsLoaded();
    };
    const setAssignmentList = list => {
        props.setAssignmentProgressIds(list);
    };
    const setCount = count => {
        props.setAssignmentCount(count);
    };
    const setHack = bool => {
        dispatch(setFilterHack(bool));
    };

    return (
        <>
            <Loader loading={props.loading} removeChildren>
                <div className={props.isPreview ? style.previewContent : style.content}>
                    <Component
                        {...props}
                        resetWidgets={resetLoading}
                        setAssignmentList={setAssignmentList}
                        setCount={setCount}
                        setFilterHack={setHack}
                    />
                </div>
            </Loader>
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
    // redux
    loading: PropTypes.bool,
    gqlData: PropTypes.object,
    orgList: PropTypes.array,
    siteList: PropTypes.array,
    organization: PropTypes.object,
    widgetsLoaded: PropTypes.array,
    schoolLevel: PropTypes.string,
    resetWidgetsLoaded: PropTypes.func,
    setAssignmentProgressIds: PropTypes.func,
    setFilterHack: PropTypes.func,
    cycles: PropTypes.array,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    filterHack: PropTypes.bool,
    listLoading: PropTypes.bool,
    levelOptions: PropTypes.object,
    treeSelection: PropTypes.array,
    DQC: PropTypes.array,
    masterList: PropTypes.array,
};

const mapStateToProps = state => ({
    loading: state.app.report.loading,
    gqlData: state.app.report.gqlData,
    orgList: state.app.report.orgList,
    siteList: state.app.report.siteList,
    organization: state.app.report.organization,
    widgetsLoaded: state.app.report.widgetsLoaded,
    cycles: state.app.report.deploymentCycles,
    schoolLevel: state.app.report.schoolLevel,
    startDate: state.app.report.startDate,
    endDate: state.app.report.endDate,
    filterHack: state.app.report.filterHack,
    listLoading: state.app.report.listLoading,
    treeSelection: state.app.report.treeSelection,
    noOrgs: state.app.report.noOrgs,
    DQC: state.app.report.DQC,
    masterList: state.app.report.masterList,
});

export default connect(mapStateToProps, {
    initializeFilters,
    resetWidgetsLoaded,
    setAssignmentProgressIds,
    setFilterHack,
    setAssignmentCount,
})(DashPlayer);
