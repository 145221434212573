import React from 'react';
import style from './style.css';

const CharAxis = props => {
    const isTop = props.type === 'top';

    return (
        <>
            {!isTop && <div className={style.topTines} />}
            <div
                className={isTop ? style.chartAxisTop : style.chartAxisBottom}
                style={props.condensed && { justifyContent: 'space-between' }}
            >
                <div
                    className={style.tick}
                    // style={props.condensed && { width: '5%' }}
                >
                    <span>10%</span>
                </div>
                {!props.condensed && (
                    <>
                        <div className={style.tick}>
                            <span>20%</span>
                        </div>
                        <div className={style.tick}>
                            <span>30%</span>
                        </div>
                        <div className={style.tick}>
                            <span>40%</span>
                        </div>
                    </>
                )}
                <div
                    className={style.tick}
                    // style={props.condensed && { width: '5%' }}
                >
                    <span>50%</span>
                </div>
                {!props.condensed && (
                    <>
                        <div className={style.tick}>
                            <span>60%</span>
                        </div>
                        <div className={style.tick}>
                            <span>70%</span>
                        </div>
                        <div className={style.tick}>
                            <span>80%</span>
                        </div>
                        <div className={style.tick}>
                            <span>90%</span>
                        </div>{' '}
                    </>
                )}
                <div
                    className={style.tick}
                    // style={props.condensed && { width: '5%' }}
                >
                    <span>100%</span>
                </div>
            </div>
        </>
    );
};

export default CharAxis;
