import React, { useState, useEffect } from 'react';
import SelectList from 'react-widgets/lib/SelectList';
import PropTypes from 'prop-types';
import DetectClickLocation from 'cccisd-detect-click-location';
import Button from 'cccisd-click-button';
import Tooltip from 'cccisd-tooltip';
import style from './style.css';
import { useDispatch, connect } from 'react-redux';
import { setDqc } from 'js/reducers/report.js';
const MultiSelect = props => {
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();
    const options = [
        { value: 0, label: 'Dishonest' },
        { value: 1, label: 'Speeder' },
        { value: 2, label: 'Skipper' },
        { value: 3, label: 'Inconsistent Responses' },
        { value: 4, label: 'Unlikely Responses' },
        { value: 5, label: 'Endorsed Fake Drug Use' },
    ];
    const dqcValues = props.DQC.map(item => item.value);
    useEffect(() => {
        props.form.setFieldValue('validation', dqcValues);
    }, [props.DQC]);

    const handleChange = value => {
        dispatch(setDqc(value));
    };

    const selectAll = () => {
        dispatch(setDqc(options));
    };

    const selectNone = () => {
        dispatch(setDqc([]));
    };

    return (
        <div className={style.wrapper}>
            <DetectClickLocation clickedOutside={() => setVisible(false)}>
                <Tooltip
                    title={
                        <div className={style.toolTip}>
                            <div className={style.tooltipItem}>
                                <strong>Dishonest:</strong> Answered <strong>not</strong> honest filling out this survey
                            </div>
                            <div className={style.tooltipItem}>
                                <strong>Speeder:</strong> Time spent answering was very brief
                            </div>
                            <div className={style.tooltipItem}>
                                <strong>Skipper:</strong> Skipped more than 50% of questions
                            </div>
                            <div className={style.tooltipItem}>
                                <strong>Inconsistent Responses:</strong> Similar/Related items had conflicting answers
                            </div>
                            <div className={style.tooltipItem}>
                                <strong>Unlikely Responses:</strong> Answers that are highly unlikely or improbable
                            </div>
                            <div className={style.tooltipItem}>
                                <strong>Endorsed Fake Drug Use:</strong> Reported using a drug that does not exist
                            </div>
                        </div>
                    }
                >
                    <Button
                        title={`Data Quality Checks (${dqcValues.length})`}
                        className="btn btn-default"
                        onClick={() => setVisible(!visible)}
                        style={{ display: 'block', marginBottom: '15px' }}
                    />
                </Tooltip>
                {visible && (
                    <div
                        style={{
                            position: 'absolute',
                            zIndex: 2,
                            marginTop: '-8px',
                            marginLeft: '8px',
                        }}
                    >
                        <Button
                            className={`btn btn-xs btn-default ${style.marginRight}`}
                            isDisabled={dqcValues.length === 6}
                            onClick={selectAll}
                            title="Select All"
                        />
                        <Button
                            className="btn btn-xs btn-default"
                            isDisabled={dqcValues.length === 0}
                            onClick={selectNone}
                            title="Deselect All"
                        />
                    </div>
                )}
                <SelectList
                    multiple
                    data={options}
                    textField="label"
                    valueField="value"
                    onChange={handleChange}
                    value={dqcValues}
                    style={{
                        display: visible ? 'block' : 'none',
                        height: 'auto',
                        marginTop: '-15px',
                        position: 'absolute',
                        zIndex: 1,
                        paddingTop: '30px',
                    }}
                />
            </DetectClickLocation>
        </div>
    );
};

MultiSelect.propTypes = {
    form: PropTypes.object,
    field: PropTypes.object,
    // redux
    DQC: PropTypes.array,
};

const mapStateToProps = state => ({
    DQC: state.app.report.DQC,
});

export default connect(mapStateToProps, {
    setDqc,
})(MultiSelect);
