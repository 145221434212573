import React from 'react';
import classnames from 'classnames';
import IconCheckmark from 'cccisd-icons/svg/checkmark';
import IconLock from 'cccisd-icons/svg/lock4';
import IconOptional from 'cccisd-icons/circle';
import IconRequired from 'cccisd-icons/circle2';
import { Player, DefaultPlayerSettingsContext } from 'cccisd-laravel-flow';
import Loader from 'cccisd-loader';
import { Html } from 'cccisd-wysiwyg';
import useWithModulesMediator from './useWithModulesMediator';
import { initialValues as initialSettings } from '../Settings';
import LessonCard from './components/LessonCard';
import style from './style.css';
import IconArrow from 'cccisd-icons/arrow-right3';

const translate = {
    en: {
        moduleText: 'MODULE',
        overview: 'Overview',
        moduleRequired: 'indicates required content',
        moduleOptional: 'indicates optional content',
    },
    es: {
        moduleText: 'MÓDULO',
        overview: 'El Resumen',
        moduleRequired: 'indica contenido requerido',
        moduleOptional: 'indica contenido opcional',
    },
};

const WithModulesMediator = props => {
    const { mediatorProps } = props;
    const language = Object.keys(translate).includes(props.language) ? props.language : 'en';
    const settings = {
        ...initialSettings,
        ...mediatorProps,
    };

    const {
        mustTakeInOrder,
        highlighting,
        requiredHighlightColor,
        optionalHighlightColor,
    } = settings;
    const { closeFlowPlayer, currentFlowProps, modules, isLoading } = useWithModulesMediator({
        ...props,
        mustTakeInOrder,
    });
    const currentModule = modules.find(m => m.isActive);
    const currentModuleIndex = modules.indexOf(currentModule);

    function getHighlightColor() {
        switch (highlighting) {
            case '':
                return '';
            case 'required':
                return requiredHighlightColor in style
                    ? style[requiredHighlightColor]
                    : requiredHighlightColor;
            case 'optional':
                return optionalHighlightColor in style
                    ? style[optionalHighlightColor]
                    : optionalHighlightColor;
            default:
                console.error(
                    `Setting value '${highlighting}' is not a valid option for highlighting.`
                );
                return '';
        }
    }

    function renderPlayerView() {
        if (!currentFlowProps) {
            return null;
        }
        return (
            <>
                <div className={style.breadcrumbs}>
                    {currentModule.title}
                    <span style={{ fontSize: '1em' }}>
                        <IconArrow spaceLeft spaceRight />
                    </span>
                    {typeof currentFlowProps.title === 'string' ? (
                        <Html content={currentFlowProps.title} />
                    ) : (
                        <Html content={currentFlowProps.title.content} />
                    )}
                </div>
                <span
                    className={classnames('pull-right', style.closeButton)}
                    onClick={closeFlowPlayer}
                >
                    &times;
                </span>
                <div className={style.flow}>
                    <Player key={currentFlowProps.handle} {...currentFlowProps} />
                </div>
            </>
        );
    }

    function renderModuleView() {
        if (currentFlowProps || !currentModule) {
            return null;
        }
        return (
            <div className={style.wrapper}>
                <div className={style.sidebar}>{renderModuleNav()}</div>
                <div className={style.content}>
                    {renderHeader()}
                    {renderLessonArea()}
                </div>
            </div>
        );
    }

    function renderModuleNav() {
        const isExtraPadding =
            (highlighting === 'optional' &&
                modules.some(m => m.lessons.every(les => !les.isRequired))) ||
            (highlighting === 'required' &&
                modules.some(m => m.lessons.some(les => les.isRequired)));

        return (
            <div className={style.moduleNav}>
                {modules.map(module => {
                    let isHighlighted;
                    switch (highlighting) {
                        case 'required':
                            isHighlighted = module.lessons.some(les => les.isRequired);
                            break;
                        case 'optional':
                            isHighlighted = module.lessons.every(les => !les.isRequired);
                            break;
                        default:
                        // leave undefined
                    }

                    if (module.isEmpty) {
                        return null;
                    }
                    return (
                        <div
                            key={module.id}
                            className={classnames(style.item, {
                                [style.current]: module.isActive,
                                [style.notStarted]: module.showDimmed,
                                [style.beforeActive]:
                                    modules.indexOf(module) === currentModuleIndex - 1,
                                [style.extraPad]: isExtraPadding,
                            })}
                            onClick={
                                module.lessons.length > 0 && module.isAllowed
                                    ? module.setActive
                                    : () => {}
                            }
                        >
                            {isHighlighted && (
                                <span
                                    className={classnames(style.moduleIcon, style.highlightIcon)}
                                    style={{ color: getHighlightColor() }}
                                >
                                    {highlighting === 'required' ? (
                                        <IconRequired />
                                    ) : (
                                        <IconOptional />
                                    )}
                                </span>
                            )}
                            <p
                                className={classnames(
                                    style.moduleHeader,
                                    module.showDimmed ? null : style.moduleHeaderActive
                                )}
                            >
                                {getModuleHeaderText(module)}
                            </p>
                            <p className={style.moduleTitle}>{module.title}</p>
                            {module.isAllLessonsComplete && !module.showDimmed && (
                                <div className={classnames(style.moduleIcon, style.checkmark)}>
                                    <IconCheckmark />
                                </div>
                            )}
                            {!module.isAllowed && (
                                <div className={style.moduleIcon}>
                                    <IconLock />
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }

    function renderHeader() {
        let highlightMessage;
        switch (highlighting) {
            case 'required':
                highlightMessage = currentModule.lessons.some(les => les.isRequired) && (
                    <div>
                        <p style={{ color: getHighlightColor() }}>
                            <span className={style.highlightIcon}>
                                <IconRequired />
                            </span>
                            &nbsp;&nbsp;{translate[language].moduleRequired}
                        </p>
                    </div>
                );
                break;
            case 'optional':
                highlightMessage = currentModule.lessons.some(les => !les.isRequired) && (
                    <div>
                        <p style={{ color: getHighlightColor() }}>
                            <span className={style.highlightIcon}>
                                <IconOptional />
                            </span>
                            &nbsp;&nbsp;{translate[language].moduleOptional}
                        </p>
                    </div>
                );
                break;
            default:
            // leave undefined
        }

        return (
            <div className={style.header}>
                <div>
                    <p className={style.headerModule}>{getModuleHeaderText(currentModule)}</p>
                    <p className={style.headerTitle}>{currentModule.title}</p>
                </div>
                {highlightMessage}
            </div>
        );
    }

    function getModuleHeaderText(module) {
        return 'header' in module
            ? module.header
            : translate[language].moduleText + ' ' + module.id;
    }

    function renderOverview() {
        if (!currentModule.overview) {
            return null;
        }
        return (
            <div className={style.overview}>
                <h5>{currentModule.overviewTitle || translate[language].overview}</h5>
                <div className={style.overviewContent}>
                    <Html content={currentModule.overview} />
                </div>
            </div>
        );
    }

    function renderLessonArea() {
        return (
            <div className={style.lessonWrapper}>
                {renderOverview()}
                <div className={style.lessonArea}>
                    {currentModule.lessons.map((item, index) => (
                        <LessonCard
                            key={index}
                            item={item}
                            language={language}
                            lessonNum={index}
                            lessons={currentModule.lessons}
                            highlighting={highlighting}
                            getHighlightColor={getHighlightColor}
                        />
                    ))}
                </div>
            </div>
        );
    }

    return (
        <DefaultPlayerSettingsContext.Provider
            value={{
                background: 'solidColor',
                backgroundColor: '#fff',
                height: '600px',
            }}
        >
            <Loader loading={isLoading}>
                {renderPlayerView()}
                {renderModuleView()}
            </Loader>
        </DefaultPlayerSettingsContext.Provider>
    );
};

export default WithModulesMediator;
