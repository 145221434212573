import React from 'react';
import Thumbs from 'js/components/Thumbs';
import ChartAxis from 'js/components/ChartAxis';
import style from './style.css';

const PrintTable = props => {
    const { sectionData, allData, title, descending, tableHeaders } = props;
    // console.log(sectionData);

    const getPercentage = (sum, n) => {
        const sectionPercentage = () => {
            return (sum / n) * 100;
        };
        const rounded = Math.round(sectionPercentage() * 10) / 10;
        if (!n) {
            return '';
        }
        return `${rounded}%`;
    };

    return (
        <table className="table stable-bordered table-striped">
            <thead>
                <tr>
                    <th colSpan={5}>
                        <div className={style.headerThumbs}>
                            <h3>{title}</h3>
                            <div className={style.legend}>
                                <Thumbs descending={descending} />
                                <ChartAxis type="top" condensed />
                            </div>
                        </div>
                    </th>
                </tr>
                <tr>
                    <th className="text-center">{tableHeaders[0]}</th>
                    <th className="text-center">{tableHeaders[1]}</th>
                    <th className="text-center">{tableHeaders[2]}</th>
                    <th className="text-center">{tableHeaders[3]}</th>
                    <th className="text-center">{tableHeaders[4]}</th>
                </tr>
            </thead>
            <tbody>
                {sectionData.map((section, i) => {
                    let rows;

                    if (!section.rowSections) {
                        rows = section.rows.map((row, id) => {
                            const sumValue = allData[`${row.evalTag}SUM`];
                            const nValue = allData[`${row.evalTag}N`];
                            const percentage = getPercentage(sumValue, nValue);
                            if (id === 0) {
                                return (
                                    <tr key={id}>
                                        <td rowSpan={section.rows.length} style={{ verticalAlign: 'middle' }}>
                                            {section.section}
                                        </td>
                                        <td>{row.description}</td>
                                        <td className="text-center">{nValue}</td>
                                        <td className="text-center">{sumValue}</td>
                                        <td className="text-center">{percentage}</td>
                                    </tr>
                                );
                            }
                            return (
                                <tr key={id}>
                                    <td>{row.description}</td>
                                    <td className="text-center">{nValue}</td>
                                    <td className="text-center">{sumValue}</td>
                                    <td className="text-center">{percentage}</td>
                                </tr>
                            );
                        });
                    } else {
                        const combinedSubsections = section.rowSections.flat();

                        const rowArr = combinedSubsections.map(row => {
                            const prefix = row.section.replace(' Use', '');
                            const newRows = row.rows.map(subRow => {
                                return {
                                    evalTag: subRow.evalTag,
                                    description: `${prefix} ${subRow.description}`,
                                };
                            });
                            return newRows;
                        });

                        const flatRow = rowArr.flat();
                        rows = flatRow.map((row, id) => {
                            const sumValue = allData[`${row.evalTag}SUM`];
                            const nValue = allData[`${row.evalTag}N`];
                            const percentage = getPercentage(sumValue, nValue);
                            if (id === 0) {
                                return (
                                    <tr key={id}>
                                        <td rowSpan={flatRow.length} style={{ verticalAlign: 'middle' }}>
                                            {section.section}
                                        </td>
                                        <td>{row.description}</td>
                                        <td className="text-center">{nValue}</td>
                                        <td className="text-center">{sumValue}</td>
                                        <td className="text-center">{percentage}</td>
                                    </tr>
                                );
                            }
                            return (
                                <tr key={id}>
                                    <td>{row.description}</td>
                                    <td className="text-center">{nValue}</td>
                                    <td className="text-center">{sumValue}</td>
                                    <td className="text-center">{percentage}</td>
                                </tr>
                            );
                        });
                    }
                    return rows;
                })}
            </tbody>
        </table>
    );
};

export default PrintTable;
