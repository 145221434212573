import React, { useRef, useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'cccisd-modal';
import classnames from 'classnames';
import CheckboxTree from 'react-checkbox-tree';
import Button from 'cccisd-click-button';
import IconCheck from 'cccisd-icons/checkbox-checked2';
import IconUncheck from 'cccisd-icons/checkbox-unchecked2';
import IconHalfcheck from 'cccisd-icons/checkbox-partial2';
import IconExpandClose from 'cccisd-icons/arrow-right';
import IconExpandOpen from 'cccisd-icons/arrow-down';
import IconParentClose from 'cccisd-icons/home9';
import IconParrentOpen from 'cccisd-icons/home8';
import IconLeaf from 'cccisd-icons/clipboard';
import InfoIcon from 'cccisd-icons/info2';
import Tooltip from 'cccisd-tooltip';
import _isEmpty from 'lodash/isEmpty';
import style from './style.css';
import _uniq from 'lodash/uniq';
import { updateTreeSelection } from 'js/reducers/report';

const SiteSelector = props => {
    const modal = useRef(null);
    const dispatch = useDispatch();

    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [siteTree, setSiteTree] = useState(null);

    useEffect(() => {
        getFieldData();
    }, [props.siteList]);

    useEffect(() => {
        props.form.setFieldValue('classList', props.treeSelection);
        setChecked(props.treeSelection);
    }, [props.treeSelection]);

    const getFieldData = () => {
        const { siteList } = props;

        const tree = siteList
            .map(({ group, childGroups: { classList } }) => {
                return {
                    value: group.groupId,
                    label: group.label,
                    children: classList.map(({ group: { groupId, label } }) => {
                        return {
                            value: groupId,
                            label,
                        };
                    }),
                };
            })
            .filter(({ children }) => !_isEmpty(children));

        setSiteTree(tree);

        let vals = [];
        const checks = [];

        siteList.forEach(({ group, childGroups: { classList } }) => {
            checks.push(group.groupId);
            const classVals = classList.map(({ group: { groupId, label } }) => {
                checks.push(groupId);
                return {
                    value: groupId,
                    label,
                };
            });

            const newVals = vals.concat(classVals);
            vals = newVals;
        });

        setChecked(checks);
        props.form.setFieldValue('classList', checks);
        dispatch(updateTreeSelection(checks));
    };

    const setSelection = () => {
        let selection = checked;
        if (selection.length === 0) {
            selection = [null];
        } else {
            selection = checked.map(s => parseInt(s, 10));
        }
        props.form.setFieldValue('classList', selection);
        dispatch(updateTreeSelection(selection));
    };

    const getLabel = type => {
        const siteIds = props.siteList.map(item => `${item.group.groupId}`);

        const classesOnly = checked.filter(item => siteIds.indexOf(`${item}`) === -1);
        if (type === 'button') {
            return `Select Sites (${classesOnly.length})`;
        }
        return (
            <div>
                {`Select Sites (${classesOnly.length})`}
                <Tooltip
                    title={
                        <div>
                            Here you can filter specific sites/schools and use the drop downs to filter the grade levels
                            of each site. Please click <strong>Done</strong> when you are finished.
                        </div>
                    }
                >
                    <InfoIcon spaceLeft />
                </Tooltip>
            </div>
        );
    };

    const handleCheck = c => {
        const ids = [...c];
        c.forEach(item => {
            props.siteList.forEach(site => {
                const match = site.childGroups.classList.find(cl => cl.group.groupId === +item);
                if (match) {
                    ids.push(`${site.group.groupId}`);
                }
            });
        });

        setChecked(_uniq(ids));
    };

    const checkAll = () => {
        const ids = props.siteList.map(item => `${item.group.groupId}`);
        props.siteList.forEach(item =>
            item.childGroups.classList.forEach(cl => {
                ids.push(`${cl.group.groupId}`);
            })
        );

        setChecked(ids);
    };

    const uncheckAll = () => {
        setChecked([]);
    };

    const closeModal = () => {
        modal.current.close();
    };

    return (
        <div className={style.flex}>
            <Modal
                ref={modal}
                size="medium"
                trigger={
                    <button type="button" className={classnames('btn', 'btn-default')} style={{ height: '36px' }}>
                        {getLabel('button')}
                    </button>
                }
                title={getLabel()}
                beforeClose={setSelection}
            >
                {siteTree && siteTree.length > 0 ? (
                    <div className={style.flex}>
                        <CheckboxTree
                            checkModel="all"
                            nodes={siteTree}
                            checked={checked}
                            expanded={expanded}
                            onCheck={c => handleCheck(c)}
                            onExpand={e => setExpanded(e)}
                            icons={{
                                check: <IconCheck />,
                                uncheck: <IconUncheck />,
                                halfCheck: <IconHalfcheck />,
                                expandClose: <IconExpandClose />,
                                expandOpen: <IconExpandOpen />,
                                expandAll: <IconExpandOpen />,
                                collapseAll: <IconExpandClose />,
                                parentClose: (
                                    <span style={{ color: '#2f847c' }}>
                                        <IconParentClose />
                                    </span>
                                ),
                                parentOpen: (
                                    <span style={{ color: '#2f847c' }}>
                                        <IconParrentOpen />
                                    </span>
                                ),
                                leaf: (
                                    <span style={{ color: '#2f847c' }}>
                                        <IconLeaf />
                                    </span>
                                ),
                            }}
                        />
                        <div className={style.buttonBox}>
                            <div className={style.flex}>
                                <Button
                                    className={classnames('btn', 'btn-default', style.grey)}
                                    title="Select All"
                                    onClick={checkAll}
                                    style={{ marginRight: '1em' }}
                                />
                                <Button
                                    className={classnames('btn', 'btn-default', style.grey)}
                                    title="Deselect All"
                                    onClick={uncheckAll}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <h4>No sites were found.</h4>
                        <h6>Try selecting a different organization</h6>
                    </>
                )}
                <div className={style.modalFooter}>
                    <Button title="Done" className={`btn btn-primary ${style.button}`} onClick={closeModal} />
                </div>
            </Modal>
        </div>
    );
};

SiteSelector.propTypes = {
    form: PropTypes.object,
    field: PropTypes.object,
    // from redux
    siteList: PropTypes.array,
    treeSelection: PropTypes.array,
};

const mapStateToProps = state => ({
    siteList: state.app.report.siteList,
    treeSelection: state.app.report.treeSelection,
});

export default connect(mapStateToProps, { updateTreeSelection })(SiteSelector);
