import React from 'react';
import style from './style.css';
import Tooltip from 'cccisd-tooltip';

const AgeChart = props => {
    const { respondentCount, arr } = props;

    const axisLabel = props.legendText;
    const chartSections = arr.filter(item => item.count > 0);

    return (
        <div className={style.chartWrapper}>
            <div className={style.barChart}>
                {chartSections.map((section, i) => {
                    const sectionPercentage = () => {
                        if (respondentCount === 0) {
                            return 0;
                        }
                        return (section.count / respondentCount) * 100;
                    };
                    const rounded = Math.round(sectionPercentage() * 10) / 10;
                    const label = section.id === '19' ? '19 or older' : section.id;
                    return (
                        <div style={{ width: `${sectionPercentage()}%` }} key={i}>
                            <Tooltip
                                title={
                                    props.type === 'age'
                                        ? `Age ${section.id}: ${rounded}%`
                                        : `${section.id}: ${rounded}%`
                                }
                            >
                                <div
                                    className={style.chartSection}
                                    style={{
                                        backgroundColor: `${section.color}`,
                                        height: '70.47px',
                                    }}
                                >
                                    <span className={style.sectionLabel} style={{ color: section.textColor }}>
                                        {rounded > 5 && `${rounded}%`}
                                    </span>
                                </div>
                                <div className={style.legendSection}>{rounded > 5 && `${label}`}</div>
                            </Tooltip>
                        </div>
                    );
                })}
            </div>
            <div className={style.xAxis} />
            <div style={{ textAlign: 'center', marginBottom: '20px', fontSize: '14pt' }}>{axisLabel}</div>
            <div className={style.detailedLegend}>
                {chartSections.map((section, i) => {
                    const sectionPercentage = () => {
                        if (respondentCount === 0) {
                            return 0;
                        }
                        return (section.count / respondentCount) * 100;
                    };
                    const rounded = Math.round(sectionPercentage() * 10) / 10;
                    return (
                        <div className={style.legendTag} style={{ width: '81.63px' }} key={i}>
                            <div
                                className={style.colorSwatch}
                                style={{
                                    backgroundColor: `${section.color}`,
                                    color: `${section.textColor}`,
                                }}
                            >
                                {rounded}%
                            </div>
                            <div>{section.id === '19' ? '19 or older' : section.id}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default AgeChart;
