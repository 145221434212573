import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { Page, PrintOnly } from 'cccisd-laravel-report';
import { client } from 'cccisd-apollo';
import { setWidgetsLoaded } from 'js/reducers/report.js';
import positiveOutcomesQuery from './graphql/positiveOutcomes.graphql';
import _isEmpty from 'lodash/isEmpty';
import style from './style.css';
import ChartSection from 'js/components/ChartSection';
import ChartAxis from 'js/components/ChartAxis';
import Thumbs from 'js/components/Thumbs';
import { formatTableSections } from '../../../Player/helpers';
import PrintTable from '../../../components/PrintTable';

const positiveOutcomes = [
    {
        section: 'Behavior',
        color: '#EFDCD7',
        barColor: '#AE4E36',
        bars: [
            {
                variable: 'PROSOC',
                description: 'Positive Behavior towards Others',
                evalTag: 'prosoc',
                tooltip: (
                    <div>
                        <p>Examples for &quot;Positive Behavior towards Others&quot;:</p>
                        <ul>
                            <li>&quot;I try to be nice to other people.&quot;</li>
                            <li>&quot;I am helpful if someone is hurt, upset, or feeling ill.&quot;</li>
                            <li>&quot;I often volunteer to help others&quot;</li>
                        </ul>
                    </div>
                ),
            },
        ],
    },
    {
        section: 'Relationships',
        color: '#EFDCD7',
        barColor: '#AE4E36',
        bars: [
            {
                variable: 'CloseFrnd ',
                description: 'Close Peer Relations',
                evalTag: 'closeFriend',
                tooltip: (
                    <div>
                        <p>
                            Reflects % of respondents reporting at least one close friend that they can talk to and
                            provide help if needed.
                        </p>
                        <p>CLOSEFRND = average of FRTALK & FRHELP where higher score = closer peer relations.</p>
                    </div>
                ),
            },
            {
                variable: 'CloseParRels',
                description: 'Close Parent Relations',
                evalTag: 'closeParent',
                tooltip: (
                    <div>
                        <p>
                            Reflects % of respondents reporting to a close relationship with their mother and/or father.
                        </p>
                        <p>
                            CLOSEPARRELS = average of CLSMOM, SHRMOM, CLSDAD, SHRDAD, ENJMOM & ENJDAD where higher score
                            = closer parent relations.
                        </p>
                    </div>
                ),
            },
            {
                variable: 'ADLHELP',
                description: 'Close Non-Parent Relations',
                evalTag: 'adultHelp',
                tooltip: (
                    <div>
                        <p>
                            Reflects % of respondents reporting to a close relationship with at least one other adult
                            that they can turn to for help and advice.
                        </p>
                        <p>Variable = ADLHELP </p>
                    </div>
                ),
            },
        ],
    },
];

const getVariables = array => {
    let variables = [];
    array.forEach(section => {
        if (section.subSections) {
            section.subSections.forEach(sub => {
                sub.bars.forEach(item => {
                    variables.push(item.variable);
                });
            });
        } else {
            section.bars.forEach(item => {
                variables.push(item.variable);
            });
        }
    });

    return variables;
};

const types = {
    positiveOutcomes: {
        query: positiveOutcomesQuery,
        variables: getVariables(positiveOutcomes),
    },
};

const PositiveOutcomes = props => {
    // const { title, description } = props.settings;
    const [positiveData, setPositiveData] = useState(null);

    useEffect(() => {
        (async () => {
            const data = await queryData(props.assignmentProgressIds, 'positiveOutcomes');
            setPositiveData(data);

            props.setWidgetsLoaded('PositiveOutcomes');
        })();
    }, [props.assignmentProgressIds]);

    const queryData = async (idList, type) => {
        const query = types[type].query;
        const variableList = types[type].variables;

        try {
            const response = await client.query({
                query,
                variables: {
                    idList,
                    variableList,
                },
            });

            if (response && response.data && !_isEmpty(response.data.flows.assignmentProgressSummary)) {
                return response.data.flows.assignmentProgressSummary;
            }
        } catch (e) {
            console.error(e);
            return null;
        }
    };
    const tableData = formatTableSections([positiveOutcomes], [positiveData], ['Behavior', 'Relationships']);

    return (
        <>
            <div className={style.barChartWrapper}>
                <Page>
                    <Thumbs />
                    <ChartAxis type="top" />
                    {positiveData ? (
                        <ChartSection sections={positiveOutcomes} data={positiveData} />
                    ) : (
                        <div style={{ width: '99%', margin: 'auto' }}>
                            <Skeleton height={275} width="100%" style={{ marginBottom: '2em', marginTop: '20px' }} />
                            <Skeleton height={275} width="100%" style={{ marginBottom: '2em' }} />
                        </div>
                    )}
                </Page>
            </div>
            <PrintOnly>
                <Page>
                    <PrintTable
                        {...tableData}
                        title="Positive Outcomes"
                        tableHeaders={[
                            'Category',
                            'Item',
                            '# of Responses',
                            '# of Positive Responses',
                            '% of Positive Responses',
                        ]}
                    />
                </Page>
            </PrintOnly>
        </>
    );
};

PositiveOutcomes.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    // redux
    assignmentProgressIds: PropTypes.array,
    setWidgetsLoaded: PropTypes.func,
};

// Filters are passed from reportTemplatePlayer HOC to the Report Player and Widgets
PositiveOutcomes.defaultProps = {
    filters: {},
};

const mapStateToProps = state => ({
    assignmentProgressIds: state.app.report.assignmentProgressIds,
});

export default connect(mapStateToProps, { setWidgetsLoaded })(PositiveOutcomes);
