import React from 'react';
import { CccisdFieldWrapper } from 'cccisd-formik';
import DatePicker from 'cccisd-datepicker';
import format from 'date-fns/format';
import { useDispatch } from 'react-redux';
import { updateDates } from 'js/reducers/report.js';
export default props => {
    const dispatch = useDispatch();
    const { field, form, type, placeholder, reactDatepickerProps } = props;

    const onChange = date => {
        const value = date ? format(date, 'YYYY-MM-DD') : null;

        form.setFieldValue(field.name, value);

        dispatch(updateDates(type, value));
    };

    return (
        <CccisdFieldWrapper {...props}>
            <div>
                <DatePicker
                    className="form-control input-sm"
                    dateFormat="MM/dd/yyyy"
                    placeholderText={placeholder}
                    {...reactDatepickerProps}
                    selected={field.value}
                    onChange={onChange}
                />
            </div>
        </CccisdFieldWrapper>
    );
};
