import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import _round from 'lodash/round';
import { Report } from 'cccisd-laravel-report';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import { client } from 'cccisd-apollo';
import Tooltip from 'cccisd-tooltip';
import notification from 'cccisd-notification';
import IconArrowRight from 'cccisd-icons/arrow-right20';
import IconArrowDown from 'cccisd-icons/arrow-down20';
import IconInfo from 'cccisd-icons/info2';
import widgets from '../widgets.js';
import filterFields from '../filterFields.js';
import assignmentQuery from './assignmentProgress.graphql';
import style from './style.css';
import _isEmpty from 'lodash/isEmpty';
import format from 'date-fns/format';
import FilterBlock from './filterBlock.js';
import isEqual from 'lodash/isEqual';

const Fortress = window.cccisd.fortress;
const userRole = Fortress.user.acting.data_type;
const isAdmin = userRole === 'uberadmin' || userRole === 'orgAdmin';

const Component = props => {
    const [reportRef, setReportRef] = useState(null);
    const [assignmentList, setAssignmentList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [noData, setNoData] = useState(false);
    const [expanded, setExpanded] = useState({
        demographics: false,
        outcomes: false,
        riskFactors: false,
        protectiveFactors: false,
        positiveOutcomes: false,
    });

    const { demographics, outcomes, riskFactors, protectiveFactors, positiveOutcomes } = props.widgets;

    useEffect(() => {
        if (!props.filterHack) {
            (async () => {
                setNoData(false);
                setLoading(true);
                props.resetWidgets();
                const gqlFilter = getGqlFilter(props.filters);

                try {
                    const response = await client.query({
                        query: assignmentQuery,
                        fetchPolicy: 'no-cors',
                        variables: {
                            filter: gqlFilter,
                        },
                    });

                    if (response && response.data && response.data.flows.assignmentProgressList.length > 0) {
                        const list = filterDqc(response.data.flows.assignmentProgressList).map(
                            item => item.assignmentProgressId
                        );

                        props.setAssignmentList(list);
                        props.setCount(response.data.flows.assignmentProgressList.length);
                        setAssignmentList(list);
                        setLoading(false);
                    } else {
                        setNoData(true);
                        setLoading(false);
                    }
                } catch (e) {
                    console.error(e);
                }
            })();
        }
    }, [props.filters]);

    const filterDqc = assignmentProgressList => {
        const {
            filters: { validation },
        } = props;

        let filteredList = assignmentProgressList;
        // DQC Checks

        if (validation.length > 0) {
            const dishonest = validation.includes(0);
            if (dishonest) {
                filteredList = filteredList.filter(item => item.variables.DQC1 === '0');
            }

            const speeder = validation.includes(1);
            if (speeder) {
                filteredList = filteredList.filter(item => item.variables.DQC2 === '0');
            }

            const skipper = validation.includes(2);
            if (skipper) {
                filteredList = filteredList.filter(item => item.variables.DQC3 === '0');
            }

            const inconsistent = validation.includes(3);
            if (inconsistent) {
                filteredList = filteredList.filter(item => item.variables.DQC4 === '0');
            }

            const unlikely = validation.includes(4);
            if (unlikely) {
                filteredList = filteredList.filter(item => item.variables.DQC5 === '0');
            }

            const fake = validation.includes(5);
            if (fake) {
                filteredList = filteredList.filter(item => item.variables.DQC6 === '0');
            }

            return filteredList;
        }

        return assignmentProgressList;
    };

    const getGradeList = () => {
        const gradeKey = {
            '6th grade': '6',
            '7th grade': '7',
            '8th grade': '8',
            '9th grade': '9',
            '10th grade': '10',
            '11th grade': '11',
            '12th grade': '12',
        };

        const sites = props.siteList.filter(item => props.treeSelection.includes(item.group.groupId));

        const selectedCycles = props.filters.cycles.map(item => item.label);

        const selectedSites = sites.filter(item => selectedCycles.includes(item.group.label));

        const assignments = props.gqlData.assignments.map(item => item.deploymentList).flat();

        const gradeList = [];

        selectedSites.forEach(site => {
            // match / get labels for Site
            const siteMatch = assignments.find(deployment => deployment.label === site.group.label);

            if (siteMatch) {
                const selectedGrades = site.childGroups.classList
                    .filter(item => props.treeSelection.includes(item.group.groupId))
                    .map(item => gradeKey[item.group.label.toLowerCase()]);

                gradeList.push({
                    deploymentId: siteMatch.deploymentId,
                    grades: selectedGrades,
                });
            }
        });
        return gradeList;
    };

    const getGqlFilter = filters => {
        const { classList, endDate, startDate } = filters;

        const filterObj = {
            AND: [],
        };

        // siteIds
        if (classList.length > 0) {
            filterObj.AND.push({
                OR: props.treeSelection.map(item => {
                    return {
                        contains: {
                            field: 'variables.site_id',
                            string: `_${item}`,
                        },
                    };
                }),
            });
        }
        if (isAdmin) {
            filterObj.AND.push({
                gte: {
                    field: 'startedDate',
                    string: `${format(startDate, 'YYYY-MM-DD')}`,
                },
            });

            // add completedDate
            filterObj.AND.push({
                lte: {
                    field: 'completedDate',
                    string: `${format(endDate, 'YYYY-MM-DD')}`,
                },
            });
        }

        // filter by DevTag Grade
        const gradeList = getGradeList();

        if (gradeList.length > 0) {
            const OR = [];

            gradeList.forEach(item => {
                item.grades.forEach(grade => {
                    const or = {
                        eq: {
                            field: 'devTags.Grade',
                            string: grade,
                        },
                    };
                    if (!OR.some(thing => isEqual(thing, or))) {
                        OR.push(or);
                    }
                });
            });

            if (OR.length > 0) {
                filterObj.AND.push({ OR });
            }
        }

        // Level
        switch (props.filters.level) {
            case 'aggregate':
                filterObj.AND.push({
                    OR: [
                        {
                            contains: {
                                field: 'deployment.deploymentHandle',
                                string: 'ms',
                            },
                        },
                        {
                            contains: {
                                field: 'deployment.deploymentHandle',
                                string: 'hs',
                            },
                        },
                    ],
                });
                break;
            case 'ms':
                filterObj.AND.push({
                    contains: {
                        field: 'deployment.deploymentHandle',
                        string: 'ms',
                    },
                });
                break;
            case 'hs':
                filterObj.AND.push({
                    contains: {
                        field: 'deployment.deploymentHandle',
                        string: 'hs',
                    },
                });
                break;
            default:
                console.error(`Invalid School Level.`);
        }

        if (!isAdmin) {
            filterObj.AND.push({ eq: { field: 'deployment.isOpen', boolean: false } });
            filterObj.AND.push({ notNull: { field: 'completedDate' } });
        }

        // "filter": {
        //     "AND": [
        //       {
        //         "OR": [
        //           {
        //             "AND": [
        //                 {"eq": {"field": "deploymentId", "int": 25}},
        //                 {
        //                   "OR": [
        //                       {"eq": {"field": "devTags.Grade", "string":"8"}},
        //                       {"eq": {"field": "devTags.Grade", "string": "6"}}
        //                    ]
        //                 }
        //                ]
        //           }
        //         ]
        //       }

        //     ]
        //   },
        return filterObj;
    };

    const toggleShow = section => {
        setExpanded({
            ...expanded,
            [section]: !expanded[section],
        });
    };

    const downloadReport = () => {
        const currentExpanded = { ...expanded };

        setExpanded({
            demographics: true,
            outcomes: true,
            positiveOutcomes: true,
            riskFactors: true,
            protectiveFactors: true,
        });

        setTimeout(() => {
            reportRef.download();
        }, 200);

        setTimeout(() => {
            setExpanded(currentExpanded);
        }, 500);
        notification({ message: 'Please wait while PDF is prepared...', type: 'success' });
    };

    const handleRef = element => {
        setReportRef(element);
    };

    const percentLoaded = _round(
        (props.widgetsLoaded.length / 5) * // 4 - one for each dropdown
            100
    );

    if (props.noOrgs) {
        return (
            <>
                <div className={style.bottomBorder} />
                <div className={style.greyContainer}>
                    <h4>No organizations were found</h4>
                </div>
            </>
        );
    }

    const orgName = props.orgList.find(item => item.groupId === +props.filters.organization).label;

    const DropdownBanner = ({ section, label }) => {
        return (
            <div
                className={style.header}
                onClick={() => {
                    toggleShow(section);
                }}
            >
                {expanded[section] ? <IconArrowDown spaceRight /> : <IconArrowRight spaceRight />}
                <span className={style.headerText}>{label}</span>
            </div>
        );
    };

    if (props.filterHack) {
        return (
            <>
                <div className={style.bottomBorder} />
                <div className={style.greyContainer}>
                    <h4>Please click Apply Filters to generate a report</h4>
                </div>
            </>
        );
    }

    if (_isEmpty(props.siteList)) {
        return (
            <>
                <div className={style.bottomBorder} />
                <div className={style.greyContainer}>
                    <h3>There are no sites available for the selected organization.</h3>
                    <h4>Please try a different organization.</h4>
                </div>
            </>
        );
    }

    if (+props.filters.organization !== props.organization.groupId) {
        return (
            <>
                <div className={style.bottomBorder} />
                <div className={style.greyContainer}>
                    <h4>Please click Apply Filters to generate a report</h4>
                </div>
            </>
        );
    }

    if (loading) {
        return (
            <>
                <div className={style.bottomBorder} />
                <div className={style.greyContainer}>
                    <Skeleton height={75} count={4} style={{ marginBottom: '1em' }} />
                </div>
            </>
        );
    }

    if (noData) {
        return (
            <>
                <div className={style.bottomBorder} />
                <div className={style.greyContainer} style={{ marginTop: '36.8px' }}>
                    <h3>There is no data that matches the current filters</h3>
                    <h4>Please select different filters</h4>
                </div>
            </>
        );
    }

    if (assignmentList.length < 10) {
        return (
            <>
                <div className={style.bottomBorder} />
                <div className={style.greyContainer} style={{ marginTop: '36.8px' }}>
                    <h3>The combination of filters that you have selected results in fewer than 10 responses.</h3>
                    <h4>Please widen the filters to include more responses.</h4>
                </div>
            </>
        );
    }

    return (
        <div className={props.isPreview ? style.reportPreview : style.reportPage}>
            {!_isEmpty(assignmentList) && (
                <>
                    {/* {!props.isPreview && ( */}
                    <div className={style.countPrint}>
                        <div className={style.respondentCount}>
                            Number of Respondents: {assignmentList.length}
                            <Tooltip title="This is the total number of participants. Participant totals for individual categories may vary from this number due to missing data.">
                                <IconInfo spaceLeft />
                            </Tooltip>
                        </div>
                        <div className={style.buttonWrapper}>
                            <Tooltip
                                title={
                                    percentLoaded >= 100
                                        ? 'Print Report PDF'
                                        : `Report Loading . . . (${percentLoaded}%)`
                                }
                                placement="top"
                            >
                                <button
                                    type="button"
                                    className={`btn ${style.downloadButton}`}
                                    onClick={downloadReport}
                                    disabled={!(percentLoaded >= 100)}
                                >
                                    Print Report
                                </button>{' '}
                            </Tooltip>
                        </div>
                    </div>
                    {/* )} */}
                    <Report
                        showPagination={false}
                        onRef={handleRef}
                        downloadFilename={`YES Overview Report - ${orgName}(${props.filters.level}) ${format(
                            props.filters.startDate,
                            'MM/DD/YYYY'
                        )} - 
                        ${format(props.filters.endDate, 'MM/DD/YYYY')}`}
                    >
                        <div className={style.greyContainer}>
                            <DropdownBanner
                                section="demographics"
                                label="Youth Experience Survey (YES): Demographics"
                            />
                            <div style={expanded.demographics ? { display: 'block' } : { display: 'none' }}>
                                {demographics}
                            </div>
                            <DropdownBanner
                                section="outcomes"
                                label="Youth Experience Survey (YES): Negative Outcomes"
                            />
                            <div style={expanded.outcomes ? { display: 'block' } : { display: 'none' }}>{outcomes}</div>
                            <DropdownBanner
                                section="positiveOutcomes"
                                label="Youth Experience Survey (YES): Positive Outcomes"
                            />
                            <div style={expanded.positiveOutcomes ? { display: 'block' } : { display: 'none' }}>
                                {positiveOutcomes}
                            </div>
                            <DropdownBanner section="riskFactors" label="Youth Experience Survey (YES): Risk Factors" />
                            <div style={expanded.riskFactors ? { display: 'block' } : { display: 'none' }}>
                                {riskFactors}
                            </div>
                            <DropdownBanner
                                section="protectiveFactors"
                                label="Youth Experience Survey (YES): Protective Factors"
                            />
                            <div style={expanded.protectiveFactors ? { display: 'block' } : { display: 'none' }}>
                                {protectiveFactors}
                            </div>
                        </div>
                    </Report>
                </>
            )}
        </div>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
    setFilterHack: PropTypes.func,
    setAssignmentList: PropTypes.func,
    // redux
    orgList: PropTypes.array,
    siteList: PropTypes.array,
    widgetsLoaded: PropTypes.array,
    assignmentHandles: PropTypes.array,
    resetWidgets: PropTypes.func,
    setCount: PropTypes.func,
    organization: PropTypes.object,
    cycles: PropTypes.array,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    filterHack: PropTypes.bool,
    noOrgs: PropTypes.bool,
    gqlData: PropTypes.object,
    treeSelection: PropTypes.array,
    masterList: PropTypes.array,
};

export default reportTemplatePlayer({
    widgets,
    getFilterFields: props => {
        return filterFields.map(field => {
            if (field.name === 'organization') {
                return {
                    ...field,
                    initialValue: props.organization.groupId,
                };
            }
            if (field.name === 'classList') {
                let classes = [];

                props.siteList.forEach(({ childGroups: { classList } }) => {
                    classList.forEach(({ group: { groupId } }) => {
                        classes.push(groupId);
                    });
                });

                return {
                    ...field,
                    initialValue: classes,
                };
            }
            if (field.name === 'cycles') {
                return {
                    ...field,
                    initialValue: props.cycles,
                };
            }
            if (field.name === 'level') {
                return {
                    ...field,
                    initialValue: props.schoolLevel,
                };
            }
            if (field.name === 'startDate') {
                return {
                    ...field,
                    initialValue: props.startDate,
                };
            }
            if (field.name === 'endDate') {
                return {
                    ...field,
                    initialValue: props.endDate,
                };
            }
            if (field.name === 'validation') {
                return {
                    ...field,
                    initialValue: props.DQC.map(item => item.value),
                };
            }
            return field;
        });
    },
    renderFilters: props => {
        return <FilterBlock {...props} />;
    },
})(Component);
