import { handleActions, createAction } from 'redux-actions';
import { client } from 'cccisd-apollo';
import groupQuery from '../graphql/groupQuery.graphql';
import deploymentCyclesQuery from '../graphql/deploymentCycles.graphql';
import _head from 'lodash/head';
import { getSiteList } from '../selectors/report';
import { addDays } from 'date-fns';

// This reducer handles storing filter settings for reports
//    Some filters are dependant on data from other filters
export const initialState = {
    loading: true,
    orgList: [],
    siteList: [],
    organization: {},
    widgetsLoaded: [],
    schoolLevel: 'aggregate',
    deploymentCycles: [],
    showAdvanced: false,
    startDate: addDays(new Date('2022-01-01'), 1),
    endDate: new Date(),
    listLoading: true,
    filterHack: true,
    gqlData: {
        assignments: [],
        groups: [],
    },
    gradeFilter: null,
    assignmentProgressIds: [],
    assignmentList: [],
    noOrgs: false,
    assignmentCount: 0,
    DQC: [
        { value: 0, label: 'Dishonest' },
        { value: 1, label: 'Speeder' },
        { value: 2, label: 'Skipper' },
        { value: 3, label: 'Inconsistent Responses' },
        { value: 4, label: 'Unlikely Responses' },
        { value: 5, label: 'Endorsed Fake Drug Use' },
    ],
    masterList: [],
};

const Fortress = window.cccisd.fortress;
const userRole = Fortress.user.acting.data_type;
const isAdmin = userRole === 'uberadmin' || userRole === 'orgAdmin';

// Actions
const SET_LOADING = 'app/report/SET_LOADING';
const SET_LIST_LOADING = 'app/report/SET_LIST_LOADING';
const SET_ORGLIST = 'app/report/SET_ORGLIST';
const SET_SITELIST = 'app/report/SET_SITELIST';
const SET_START_DATE = 'app/report/SET_START_DATE';
const SET_END_DATE = 'app/report/SET_END_DATE';
const SET_ORGANIZATION = 'app/report/SET_ORGANIZATION';
const SET_GQL_DATA = 'app/report/SET_GQL_DATA';
const SET_SCHOOL_LEVEL = 'app/report/SET_SCHOOL_LEVEL';
const SET_ASSIGNMENT_PROGRESS_IDS = 'app/report/SET_ASSIGNMENT_PROGRESS_IDS';
const SET_WIDGETS_LOADED = 'app/report/SET_WIDGETS_LOADED';
const SET_DEPLOYMENT_CYCLES = 'app/report/SET_DEPLOYMENT_CYCLES';
const RESET_WIDGETS_LOADED = 'app/report/RESET_WIDGETS_LOADED';
const SET_SHOW_ADVANCED = 'app/report/SET_SHOW_ADVANCED';
const SET_TREE_SELECTION = 'app/report/SET_TREE_SELECTION';
const SET_DQC = 'app/report/SET_DQC';
const SET_FILTER_HACK = 'app/report/SET_FILTER_HACK';
const SET_ASSIGNMENT_LIST = 'app/report/SET_ASSIGNMENT_LIST';
const SET_NO_ORGS = 'app/report/SET_NO_ORGS';
const UNSET_NO_ORGS = 'app/report/UNSET_NO_ORGS';
const SET_ASSIGNMENT_COUNT = 'app/report/SET_ASSIGNMENT_COUNT';
const SET_MASTER_LIST = 'app/report/SET_MASTER_LIST';
const SET_CURRENT_CYCLES = 'app/report/SET_CURRENT_CYCLES';
// Action Creators

// Loading / Visibility
export const setLoading = createAction(SET_LOADING);
export const setListLoading = createAction(SET_LIST_LOADING);
export const setShowAdvanced = createAction(SET_SHOW_ADVANCED);
export const setWidgetsLoaded = createAction(SET_WIDGETS_LOADED);
export const resetWidgetsLoaded = createAction(RESET_WIDGETS_LOADED);

// Raw data
export const setGqlData = createAction(SET_GQL_DATA);
export const setAssignmentList = createAction(SET_ASSIGNMENT_LIST);

// Field Options
export const setOrgList = createAction(SET_ORGLIST);
export const setSiteList = createAction(SET_SITELIST);

// Field / Filter Values
export const setOrganization = createAction(SET_ORGANIZATION);
export const setTreeSelection = createAction(SET_TREE_SELECTION);
export const setSchoolLevel = createAction(SET_SCHOOL_LEVEL);

export const setDeploymentCycles = createAction(SET_DEPLOYMENT_CYCLES);
export const setStartDate = createAction(SET_START_DATE);
export const setEndDate = createAction(SET_END_DATE);
export const setDqc = createAction(SET_DQC);
export const setAssignmentProgressIds = createAction(SET_ASSIGNMENT_PROGRESS_IDS);
export const setAssignmentCount = createAction(SET_ASSIGNMENT_COUNT);

// Filter Hack - prevent initial firing of gql query
export const setFilterHack = createAction(SET_FILTER_HACK);
export const setNoOrgs = createAction(SET_NO_ORGS);
export const unsetNoOrgs = createAction(UNSET_NO_ORGS);

// master list
export const setMasterList = createAction(SET_MASTER_LIST);
export const setCurrentCycles = createAction(SET_CURRENT_CYCLES);

export const initializeFilters = () => {
    return async (dispatch, getState) => {
        try {
            const response = await client.query({
                query: groupQuery,
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            });
            const list = response.data && response.data.groups.groupingUnitList;
            const masterList =
                list &&
                list.map(groupingUnit => {
                    const schools = groupingUnit.childGroups.siteList.map(school => {
                        const classes = school.childGroups.classList.map(cl => {
                            return { id: cl.group.groupId, label: cl.group.label };
                        });
                        return {
                            id: school.group.groupId,
                            label: school.group.label,
                            classes,
                        };
                    });
                    return {
                        id: groupingUnit.group.groupId,
                        label: groupingUnit.group.label,
                        schools,
                    };
                });

            if (masterList.length > 0) {
                dispatch(setMasterList(masterList));
            }

            const orgList =
                list &&
                list.map(item => {
                    return { groupId: item.group.groupId, label: item.group.label };
                });
            if (orgList.length === 0) {
                dispatch(setNoOrgs());
                dispatch(setLoading(false));
            } else {
                const siteList = _head(list).childGroups.siteList;
                const initialOrg = _head(orgList);
                const assignments = response.data.flows.assignmentList;

                const checks = [];

                siteList.forEach(({ group, childGroups: { classList } }) => {
                    checks.push(group.groupId);
                    classList.forEach(item => {
                        checks.push(item.group.groupId);
                    });
                });
                dispatch(unsetNoOrgs());
                dispatch(setTreeSelection(checks));
                dispatch(
                    setGqlData({
                        orgs: response.data.groups.groupingUnitList,
                        assignments,
                    })
                );

                dispatch(setOrgList(orgList));
                dispatch(setOrganization(initialOrg));

                dispatch(setSiteList(siteList));
                await dispatch(getDeploymentCycles());
                dispatch(setListLoading(false));
                dispatch(setLoading(false));
            }
        } catch (e) {
            console.error(e);
        }
    };
};

export const getDeploymentCycles = assignmentHandles => {
    return async (dispatch, getState) => {
        const currentState = getState().app.report;
        const schoolLevel = currentState.schoolLevel;
        const treeSelection = currentState.treeSelection;

        const selectedSites = currentState.siteList.filter(item => treeSelection.includes(item.group.groupId));

        let filter = { AND: [] };

        switch (schoolLevel) {
            case 'aggregate':
                filter.AND.push({
                    OR: [
                        { contains: { field: 'deploymentHandle', string: 'ms' } },
                        { contains: { field: 'deploymentHandle', string: 'hs' } },
                    ],
                });
                break;
            case 'ms':
                filter.AND.push({ contains: { field: 'deploymentHandle', string: 'ms' } });
                break;
            case 'hs':
                filter.AND.push({ contains: { field: 'deploymentHandle', string: 'hs' } });
                break;
            default:
                console.error(`Invalid School Level.`);
        }

        if (!isAdmin) {
            filter.AND.push({ eq: { field: 'isOpen', boolean: false } });
        }

        try {
            const response = await client.query({
                query: deploymentCyclesQuery,
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
                variables: {
                    filter,
                },
            });
            const deploymentCycles = [];
            const assignmentList = response.data.flows.assignmentList.map(item => item.deploymentList).flat();

            selectedSites.forEach(site => {
                // match / get labels for Site
                const groupId = site.group.groupId;
                const handleMatch = assignmentList.find(thing => {
                    const handleId = thing.deploymentHandle && +thing.deploymentHandle.split('_')[0];

                    if (handleId === groupId) {
                        return thing;
                    }
                    return null;
                });

                // const siteMatch = assignmentList.find(
                //     deployment => deployment.label === site.group.label
                // );
                if (handleMatch) {
                    deploymentCycles.push({
                        value: handleMatch.deploymentId,
                        label: handleMatch.label,
                    });
                }
            });

            dispatch(setDeploymentCycles(deploymentCycles));
        } catch (e) {
            console.error(e);
        }
    };
};

export const updateDeploymentCycles = cycles => {
    return async (dispatch, getState) => {
        dispatch(setCurrentCycles(cycles));
        const currentState = getState().app.report;
        const deploymentCycles = currentState.deploymentCycles;
        const masterList = currentState.masterList;
        const treeSelection = currentState.treeSelection;
        const org = currentState.organization;
        const currentOrg = masterList.find(item => item.id === org.groupId);

        const selectedCycles = cycles.map(id => {
            const match = deploymentCycles.find(item => item.value === id);
            return match;
        });
        // All possible Tree selections
        const cycleSelectionIds = [];

        selectedCycles.forEach(cycle => {
            const { label } = cycle;
            // filter to school
            const match = currentOrg.schools.find(school => school.label === label);
            if (match) {
                cycleSelectionIds.push(match.id);
                match.classes.forEach(cl => {
                    cycleSelectionIds.push(cl.id);
                });
            }
        });
        const finalList = treeSelection.filter(id => cycleSelectionIds.find(cid => id === cid));
        await dispatch(setTreeSelection(finalList));
    };
    // setdeploymentcycles
    // filter tree selection to
    // update TreeSelection (Flat Array)
};

export const updateOrganization = org => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true));
        dispatch(setListLoading(true));
        dispatch(unsetNoOrgs());
        await dispatch(setAssignmentProgressIds([]));
        await dispatch(setFilterHack(true));
        await dispatch(setOrganization(org));

        const currentState = getState().app.report;
        const siteList = getSiteList(currentState, org);

        dispatch(setSiteList(siteList));

        dispatch(setSchoolLevel('aggregate'));
        await dispatch(getDeploymentCycles());
        dispatch(setListLoading(false));
        dispatch(setLoading(false));
    };
};

export const updateSchoolLevel = level => {
    return async (dispatch, getState) => {
        dispatch(setListLoading(true));
        dispatch(setSchoolLevel(level));
        await dispatch(getDeploymentCycles(level));
        dispatch(setListLoading(false));
    };
};

export const updateDates = (type, value) => {
    return async (dispatch, getState) => {
        const date = addDays(new Date(value), 1);

        if (type === 'start') {
            dispatch(setStartDate(date));
        }
        if (type === 'end') {
            dispatch(setEndDate(date));
        }
    };
};

export const updateTreeSelection = selection => {
    return async (dispatch, getState) => {
        await dispatch(setTreeSelection(selection));
        dispatch(setListLoading(true));
        await dispatch(getDeploymentCycles());
        dispatch(setListLoading(false));
    };
};

// Reducers
export default handleActions(
    {
        [SET_LOADING]: (state, action) => ({
            ...state,
            loading: action.payload,
        }),
        [SET_LIST_LOADING]: (state, action) => ({
            ...state,
            listLoading: action.payload,
        }),
        [SET_WIDGETS_LOADED]: (state, action) => {
            if (!state.widgetsLoaded.includes(action.payload)) {
                return { ...state, widgetsLoaded: [action.payload, ...state.widgetsLoaded] };
            }
            return state;
        },
        [RESET_WIDGETS_LOADED]: (state, action) => ({
            ...state,
            widgetsLoaded: [],
        }),
        [SET_SHOW_ADVANCED]: (state, action) => ({
            ...state,
            showAdvanced: action.payload,
        }),
        [SET_ORGLIST]: (state, action) => ({
            ...state,
            orgList: action.payload,
        }),
        [SET_SITELIST]: (state, action) => ({
            ...state,
            siteList: action.payload,
        }),
        [SET_ORGANIZATION]: (state, action) => ({
            ...state,
            organization: action.payload,
        }),
        [SET_GQL_DATA]: (state, action) => ({
            ...state,
            gqlData: action.payload,
        }),

        [SET_ASSIGNMENT_PROGRESS_IDS]: (state, action) => ({
            ...state,
            assignmentProgressIds: action.payload,
        }),
        [SET_ASSIGNMENT_COUNT]: (state, action) => ({
            ...state,
            assignmentCount: action.payload,
        }),
        [SET_SCHOOL_LEVEL]: (state, action) => ({
            ...state,
            schoolLevel: action.payload,
        }),
        [SET_DEPLOYMENT_CYCLES]: (state, action) => ({
            ...state,
            deploymentCycles: action.payload,
        }),
        [SET_START_DATE]: (state, action) => ({
            ...state,
            startDate: action.payload,
        }),
        [SET_END_DATE]: (state, action) => ({
            ...state,
            endDate: action.payload,
        }),
        [SET_DQC]: (state, action) => ({
            ...state,
            DQC: action.payload,
        }),

        [SET_FILTER_HACK]: (state, action) => ({
            ...state,
            filterHack: action.payload,
        }),
        [SET_TREE_SELECTION]: (state, action) => ({
            ...state,
            treeSelection: action.payload,
        }),
        [SET_ASSIGNMENT_LIST]: (state, action) => ({
            ...state,
            assignmentList: action.payload,
        }),
        [SET_NO_ORGS]: (state, action) => ({
            ...state,
            noOrgs: true,
        }),
        [UNSET_NO_ORGS]: (state, action) => ({
            ...state,
            noOrgs: false,
        }),
        [SET_MASTER_LIST]: (state, action) => ({
            ...state,
            masterList: action.payload,
        }),
        [SET_CURRENT_CYCLES]: (state, action) => ({
            ...state,
            currentCycles: action.payload,
        }),
    },
    initialState
);
