import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { Page, PrintOnly } from 'cccisd-laravel-report';
import { client } from 'cccisd-apollo';
import { setWidgetsLoaded } from 'js/reducers/report.js';
import communityQuery from './graphql/community.graphql';
import schoolQuery from './graphql/school.graphql';
import familyQuery from './graphql/family.graphql';
import individualQuery from './graphql/individual.graphql';
import _isEmpty from 'lodash/isEmpty';
import style from './style.css';
import ChartSection from 'js/components/ChartSection';
import ChartAxis from 'js/components/ChartAxis';
import Thumbs from 'js/components/Thumbs';
import { formatTableSections } from '../../../Player/helpers';
import PrintTable from '../../../components/PrintTable';

const community = [
    {
        section: 'Community',
        color: '#E2DEEB',
        barColor: '#705B99',
        bars: [
            {
                variable: 'COMMACT',
                description: 'Opportunities for Prosocial Involvement',
                evalTag: 'opportunity',
                tooltip: (
                    <div>
                        <p>
                            Reflects % of respondents reporting multiple opportunities for prosocial involvement in
                            their community.
                        </p>
                        <p>
                            COMMACT = average of BAND, BGCLUB, CHURCH, SCOUT, SERCLUB, SPORT, and TALKNEI_RC where a
                            higher score equals more opportunities for prosocial activities
                        </p>
                    </div>
                ),
            },
            {
                variable: 'POSCOMMINV',
                description: 'Recognnition for Prosocial Involvement',
                evalTag: 'recognition',
                tooltip: (
                    <div>
                        <p>
                            Reflects % of respondents reporting acknowledgment from their community for prosocial
                            involvement.
                        </p>
                        <p>
                            POSCOMMINV = average of NHENC, NHGJOB, and NHPROUD where a higher score equals more rewards
                            for prosocial behavior
                        </p>
                    </div>
                ),
            },
            {
                variable: 'COMMCOLLEFF',
                description: 'Neighborhood Collective Efficacy',
                evalTag: 'collective',
                tooltip: (
                    <div>
                        <p>
                            Reflects % of respondents reporting a greater willingness from their community to intervene
                            for common goods.
                        </p>
                        <p>
                            COMMCOLLEFF = average of FIGHTNH, HANGNH, NEIHELP, and NEITRUST where a higher score equals
                            greater community collective efficacy
                        </p>
                        <p>(Asked of High School Only)</p>
                    </div>
                ),
            },
        ],
    },
];
const communityMS = [
    {
        section: 'Community',
        color: '#E2DEEB',
        barColor: '#705B99',
        bars: [
            {
                variable: 'COMMACT',
                description: 'Opportunities for Prosocial Involvement',
                evalTag: 'opportunity',
                tooltip: (
                    <div>
                        <p>
                            Reflects % of respondents reporting multiple opportunities for prosocial involvement in
                            their community.
                        </p>
                        <p>
                            COMMACT = average of BAND, BGCLUB, CHURCH, SCOUT, SERCLUB, SPORT, and TALKNEI_RC where a
                            higher score equals more opportunities for prosocial activities
                        </p>
                    </div>
                ),
            },
            {
                variable: 'POSCOMMINV',
                description: 'Recognnition for Prosocial Involvement',
                evalTag: 'recognition',
                tooltip: (
                    <div>
                        <p>
                            Reflects % of respondents reporting acknowledgment from their community for prosocial
                            involvement.
                        </p>
                        <p>
                            POSCOMMINV = average of NHENC, NHGJOB, and NHPROUD where a higher score equals more rewards
                            for prosocial behavior
                        </p>
                    </div>
                ),
            },
        ],
    },
];
const school = [
    {
        section: 'School',
        color: '#E2DEEB',
        barColor: '#705B99',
        bars: [
            {
                variable: 'PROSOCSCHINV',
                description: 'School Opportunities for Prosocial Involvement',
                evalTag: 'schoolOpportunity',
                tooltip: (
                    <div>
                        <p>
                            Reflects % of respondents reporting multiple opportunities for prosocial involvement in
                            their school.
                        </p>
                        <p>
                            PROSOCSCHINV = average of SCHACT, SCHCLUB, SCHDISC, SCHPRO, and TALKTCH where a higher score
                            equals more opportunities for prosocial activities
                        </p>
                    </div>
                ),
            },
            {
                variable: 'POSSCHINV',
                description: 'School Recognition for Prosocial Involvement',
                evalTag: 'schoolRecognition',
                tooltip: (
                    <div>
                        <p>
                            Reflects % of respondents reporting acknowledgment from their school for prosocial
                            involvement.
                        </p>
                        <p>
                            POSSCHINV = average of SAFESCH, SCHPAR, TCHNOT, and TCHPRA where a higher score equals more
                            rewards for prosocial behavior
                        </p>
                    </div>
                ),
            },
            {
                variable: 'ACASELFEFF',
                description: 'Confidence in Academic Success',
                evalTag: 'efficacy',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting confidence in their academic capacity.</p>
                        <p>
                            ACASELFEFF = average of CERTFIG, CERTLERN, CERTMAST, CERTTRY, and CERTWORK where a higher
                            score equals greater academic self-efficacy
                        </p>
                    </div>
                ),
            },
        ],
    },
];
const family = [
    {
        section: 'Family',
        color: '#E2DEEB',
        barColor: '#705B99',
        bars: [
            {
                variable: 'POSFAMREL',
                description: 'Attached to both parents/caregivers',
                evalTag: 'attachement',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting a close relationship with their parents or guardians.</p>
                        <p>
                            POSFAMREL = average of CLSDAD_RC, CLSMOM_RC, SHRDAD_RC, and SHRMOM_RC where a higher score
                            equals a closer relationship
                        </p>
                    </div>
                ),
            },
            {
                variable: 'POSINVPAR',
                description: 'Family Opportunity for Positive Involvement',
                evalTag: 'famOpportunity',
                tooltip: (
                    <div>
                        <p>
                            Reflects % of respondents reporting multiple opportunities for prosocial involvement with
                            their parents.
                        </p>
                        <p>
                            POSINVPAR = average of ASKHELP, FAMDEC, and FAMFUN where a higher score equals more
                            opportunities for prosocial activities
                        </p>
                    </div>
                ),
            },
            {
                variable: 'POSFAMINV',
                description: 'Family Recognition of Positive Involvement',
                evalTag: 'famRecognition',
                tooltip: (
                    <div>
                        <p>
                            Reflects % of respondents reporting acknowledgment from their family for prosocial
                            involvement.
                        </p>
                        <p>
                            POSFAMINV = average of ENJDAD_RC, ENJMOM_RC, PROUD, and PARNOT where a higher score equals
                            more rewards for prosocial behavior
                        </p>
                    </div>
                ),
            },
            {
                variable: 'PARPOSDISC',
                description: 'Parental Use of Positive Discipline Strategies',
                evalTag: 'discipline',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting positive discipline strategies from their parents.</p>
                        <p>
                            PARPOSDISC = average of MISBDISA, MISBLIST, and MISBPRIV where a higher score equals more
                            positive discipline
                        </p>
                    </div>
                ),
            },
            {
                variable: 'FAMEDINV',
                description: 'Parental Involvement in Education',
                evalTag: 'involvement',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting parental involvement in their education.</p>
                        <p>
                            FAMEDINV = average of PARCLASS, PARHELP, PARPRGS, PARSCHL, and PARSPORT where a higher score
                            equals more involvement in youth’s education
                        </p>
                    </div>
                ),
            },
        ],
    },
];
const individual = [
    {
        section: 'Individual',
        color: '#E2DEEB',
        barColor: '#705B99',
        bars: [
            {
                variable: 'REFUSESKILLS',
                description: 'Makes Good Choices in Hard Situations',
                evalTag: 'refuse',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting social competence.</p>
                        <p>
                            REFUSESKILLS = average of BUMP_RC, GOOUT_RC, PARTY_RC, and SHOPLFT_RC where a higher score
                            equals greater social skills
                        </p>
                    </div>
                ),
            },
            {
                variable: 'HIGHSTANDBEH',
                description: 'Clear Standards for Behavior',
                evalTag: 'standards',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting…</p>
                        <p>
                            HIGHSTANDBEH = average of HONPAR, OKCHEAT_RV, OKFGHT_RV, and OKTAKE_RV where a higher score
                            equals higher standards
                        </p>
                    </div>
                ),
            },

            {
                variable: 'PROSOCFRNDS',
                description: 'Prosocial Friends',
                evalTag: 'profriend',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting friends participating in prosocial behavior.</p>
                        <p>
                            PROSOCFRNDS = average of FRCLUB, FRCOM, FRLKSCH, FRREL, and FRSCH where a higher score
                            equals more prosocial friends
                        </p>
                    </div>
                ),
            },
            {
                variable: 'PROSOCINV',
                description: 'Prosocial Involvement',
                evalTag: 'proinv',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting friends participating in prosocial activities.</p>
                        <p>
                            PROSOCINV = average of YRCLUB, YREXTRA, and YRVOL where a higher score equals more prosocial
                            activities
                        </p>
                    </div>
                ),
            },
            {
                variable: 'PROSOCCOOL',
                description: 'Rewards for Prosocial Involvement',
                evalTag: 'prosocreward',
                tooltip: (
                    <div>
                        <p>
                            Reflects % of respondents reporting acknowledgement from friends or peers for prosocial
                            involvement.
                        </p>
                        <p>
                            PROSOCCOOL = average of COLLDEF, COOLSCH, and COOLVOL where a higher score equals greater
                            perceived rewards of prosocial involvement
                        </p>
                    </div>
                ),
            },
            {
                variable: 'HLTHACTIV',
                description: 'Exercise / Physical Activity',
                evalTag: 'exercise',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting participation in regular exercise.</p>
                        <p>Variable = HLTHACTIV</p>
                    </div>
                ),
            },
        ],
    },
];

const getVariables = array => {
    let variables = [];
    array.forEach(section => {
        if (section.subSections) {
            section.subSections.forEach(sub => {
                sub.bars.forEach(item => {
                    variables.push(item.variable);
                });
            });
        } else {
            section.bars.forEach(item => {
                variables.push(item.variable);
            });
        }
    });

    return variables;
};

const types = {
    community: {
        query: communityQuery,
        variables: getVariables(community),
    },
    school: {
        query: schoolQuery,
        variables: getVariables(school),
    },
    family: {
        query: familyQuery,
        variables: getVariables(family),
    },
    // old
    individual: {
        query: individualQuery,
        variables: getVariables(individual),
    },
};

const ProtectiveFactors = props => {
    // const { title, description } = props.settings;

    const [communityData, setCommunityData] = useState(null);
    const [schoolData, setSchoolData] = useState(null);
    const [familyData, setFamilyData] = useState(null);
    const [individualData, setIndividualData] = useState(null);
    const isMSOnly = props.filters.level === 'middle_school';
    useEffect(() => {
        (async () => {
            const communityValues = await queryData(props.assignmentProgressIds, 'community');
            setCommunityData(communityValues);

            const schoolValues = await queryData(props.assignmentProgressIds, 'school');
            setSchoolData(schoolValues);

            const familyValues = await queryData(props.assignmentProgressIds, 'family');
            setFamilyData(familyValues);

            const individualValues = await queryData(props.assignmentProgressIds, 'individual');
            setIndividualData(individualValues);

            props.setWidgetsLoaded('protectiveFactors');
        })();
    }, [props.assignmentProgressIds]);

    const queryData = async (idList, type) => {
        const query = types[type].query;
        const variableList = types[type].variables;

        try {
            const response = await client.query({
                query,
                variables: {
                    idList,
                    variableList,
                },
            });

            if (response && response.data && !_isEmpty(response.data.flows.assignmentProgressSummary)) {
                return response.data.flows.assignmentProgressSummary;
            }
        } catch (e) {
            console.error(e);
            return null;
        }
    };

    const sections = isMSOnly ? [communityMS, school, family, individual] : [community, school, family, individual];

    const tableData = formatTableSections(
        sections,
        [communityData, schoolData, familyData, individualData],
        ['Community', 'School', 'Family', 'Individual']
    );

    return (
        <>
            <div className={style.barChartWrapper}>
                <Page>
                    <Thumbs />
                    <ChartAxis type="top" />
                    {communityData ? (
                        <ChartSection sections={isMSOnly ? communityMS : community} data={communityData} />
                    ) : (
                        <div style={{ width: '99%', margin: 'auto' }}>
                            <Skeleton height={275} width="100%" style={{ marginBottom: '2em', marginTop: '40px' }} />
                        </div>
                    )}
                    {schoolData ? (
                        <ChartSection sections={school} data={schoolData} />
                    ) : (
                        <div style={{ width: '99%', margin: 'auto' }}>
                            <Skeleton height={275} width="100%" style={{ marginBottom: '2em', marginTop: '20px' }} />
                        </div>
                    )}
                </Page>
                <Page>
                    <PrintOnly>
                        <Thumbs />
                        <ChartAxis type="top" />
                    </PrintOnly>
                    {familyData ? (
                        <ChartSection sections={family} data={familyData} />
                    ) : (
                        <div style={{ width: '99%', margin: 'auto' }}>
                            <Skeleton height={400} width="100%" style={{ marginBottom: '2em', marginTop: '20px' }} />
                        </div>
                    )}
                </Page>
                <Page>
                    <PrintOnly>
                        <Thumbs />
                        <ChartAxis type="top" />
                    </PrintOnly>
                    {individualData ? (
                        <ChartSection sections={individual} data={individualData} />
                    ) : (
                        <div style={{ width: '99%', margin: 'auto' }}>
                            <Skeleton height={400} width="100%" style={{ marginBottom: '2em', marginTop: '20px' }} />
                        </div>
                    )}
                </Page>
            </div>
            <PrintOnly>
                <Page>
                    <PrintTable
                        {...tableData}
                        title="Protective Factors"
                        tableHeaders={[
                            'Category',
                            'Item',
                            '# of Responses',
                            '# of Positive Responses',
                            '% of Positive Responses',
                        ]}
                    />
                </Page>
            </PrintOnly>
        </>
    );
};

ProtectiveFactors.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    // redux
    assignmentProgressIds: PropTypes.array,
    setWidgetsLoaded: PropTypes.func,
};

// Filters are passed from reportTemplatePlayer HOC to the Report Player and Widgets
ProtectiveFactors.defaultProps = {
    filters: {},
};

const mapStateToProps = state => ({
    assignmentProgressIds: state.app.report.assignmentProgressIds,
});

export default connect(mapStateToProps, { setWidgetsLoaded })(ProtectiveFactors);
