import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { Page, PrintOnly } from 'cccisd-laravel-report';
import { client } from 'cccisd-apollo';
import { setWidgetsLoaded } from 'js/reducers/report.js';
import communityQuery from './graphql/community.graphql';
import schoolQuery from './graphql/school.graphql';
import familyQuery from './graphql/family.graphql';
import individualQuery from './graphql/individual.graphql';
import individualOtherQuery from './graphql/individualRest.graphql';
import _isEmpty from 'lodash/isEmpty';
import style from './style.css';
import ChartSection from 'js/components/ChartSection';
import ChartAxis from 'js/components/ChartAxis';
import Thumbs from 'js/components/Thumbs';
import { formatTableSections } from '../../../Player/helpers';
import PrintTable from '../../../components/PrintTable';
// colors:
// dark = #AE4E36
// light = #EFDCD7
// legend = #888888k
const community = [
    {
        section: 'Community',
        color: '#D0E1F4',
        barColor: '#146AC6',
        bars: [
            {
                variable: 'LOWNHATTACH',
                description: 'Low Neighborhood Attachment',
                evalTag: 'lowAttach',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents that report low neighborhood attachment.</p>
                        <p>
                            LOWNHATTACH = average of LIKENH, MISSNH, and OUTNH where a higher score equals lower
                            neighborhood attachment
                        </p>
                    </div>
                ),
            },
            {
                variable: 'COMMDISORG',
                description: 'Community Property Damage, Violence and Crime',
                evalTag: 'commDisorg',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents that report disorganization within their community.</p>
                        <p>
                            COMMDISORG = average of NHCRIME, NHEMPTY, NHFIGHT, NHGRAFF, NHRACE, SAFENH_RV where a higher
                            score equals greater community disorganization
                        </p>
                    </div>
                ),
            },
            {
                variable: 'TRANSITIONS',
                description: 'Transitions and Mobility',
                evalTag: 'transitions',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting frequent moves to new schools or homes.</p>
                        <p>
                            TRANSITIONS = sum of CHHMK_RC, CHHMYR, CHSCHK_RC, and CHSCHYR where a higher score means
                            more transitions over the youth’s lifetime
                        </p>
                    </div>
                ),
            },
            {
                variable: 'DRUGSINNH',
                description: 'Percieved Availability of Drugs',
                evalTag: 'drugs',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting availability of drugs within their community.</p>
                        <p>
                            DRUGSINNH = average of GETALC, GETCIG, GETDRUG, and GETMAR where a higher score means
                            greater availability of drugs
                        </p>
                    </div>
                ),
            },
            {
                variable: 'GETGUN',
                description: 'Percieved Availability of Handguns',
                evalTag: 'guns',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting availability of handguns within their community.</p>
                        <p>Variable = GETGUN</p>
                    </div>
                ),
            },
            {
                variable: 'DISCRIMNH',
                description: 'Percieved Racial Discrimination',
                evalTag: 'discrim',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting racial discrimination within their community.</p>
                        <p>
                            DISCRIMNH = average of RACEDISC, RACEHRD_RC and RACETRT where a higher score equals greater
                            discrimination in the community
                        </p>
                        <p>(Asked of High School Only)</p>
                    </div>
                ),
            },
        ],
    },
];
const communityMS = [
    {
        section: 'Community',
        color: '#D0E1F4',
        barColor: '#146AC6',
        bars: [
            {
                variable: 'LOWNHATTACH',
                description: 'Low Neighborhood Attachment',
                evalTag: 'lowAttach',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents that report low neighborhood attachment.</p>
                        <p>
                            LOWNHATTACH = average of LIKENH, MISSNH, and OUTNH where a higher score equals lower
                            neighborhood attachment
                        </p>
                    </div>
                ),
            },
            {
                variable: 'COMMDISORG',
                description: 'Community Disorganization',
                evalTag: 'commDisorg',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents that report disorganization within their community.</p>
                        <p>
                            COMMDISORG = average of NHCRIME, NHEMPTY, NHFIGHT, NHGRAFF, NHRACE, SAFENH_RV where a higher
                            score equals greater community disorganization
                        </p>
                    </div>
                ),
            },
            {
                variable: 'TRANSITIONS',
                description: 'Transitions and Mobility',
                evalTag: 'transitions',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting frequent moves to new schools or homes.</p>
                        <p>
                            TRANSITIONS = sum of CHHMK_RC, CHHMYR, CHSCHK_RC, and CHSCHYR where a higher score means
                            more transitions over the youth’s lifetime
                        </p>
                    </div>
                ),
            },
            {
                variable: 'DRUGSINNH',
                description: 'Percieved Availability of Drugs',
                evalTag: 'drugs',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting availability of drugs within their community.</p>
                        <p>
                            DRUGSINNH = average of GETALC, GETCIG, GETDRUG, and GETMAR where a higher score means
                            greater availability of drugs
                        </p>
                    </div>
                ),
            },
            {
                variable: 'GETGUN',
                description: 'Percieved Availability of Handguns',
                evalTag: 'guns',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting availability of handguns within their community.</p>
                        <p>Variable = GETGUN</p>
                    </div>
                ),
            },
        ],
    },
];
const school = [
    {
        section: 'School',
        color: '#D0E1F4',
        barColor: '#146AC6',
        bars: [
            {
                variable: 'ACAFAIL',
                description: 'Academic Failure',
                evalTag: 'acafail',
                tooltip: (
                    <div>
                        <p>
                            Reflects % of respondents reporting low grades in the past year (Ds and Fs) and low grades
                            in comparison to their peers.
                        </p>
                        <p>
                            ACAFAIL = average of GRADES and BTRGRA where a higher score equals greater academic failure
                        </p>
                    </div>
                ),
            },
            {
                variable: 'LOWSCHINT',
                description: 'Low School Interest',
                evalTag: 'lowInt',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting a lower interest in school.</p>
                        <p>
                            LOWSCHINT = average of SCHIMP, SCHINT, and SCHLRN where a higher score equals lower interest
                            in school
                        </p>
                    </div>
                ),
            },
            {
                variable: 'LOWSCHENJ',
                description: 'Low School Enjoyment',
                evalTag: 'lowEnj',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting a lower enjoyment while attending school.</p>
                        <p>
                            LOWSCHENJ = average of HTSCH, ENJSCH_RV, and BWSCH_RV where a higher score equals lower
                            enjoyment in school
                        </p>
                    </div>
                ),
            },
            {
                variable: 'REPEAT',
                description: 'Past Grade Retention',
                evalTag: 'repeat',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents that report repeating a grade.</p>
                        <p>Variable = REPEAT</p>
                    </div>
                ),
            },
        ],
    },
];
const family = [
    {
        section: 'Family',
        color: '#D0E1F4',
        barColor: '#146AC6',
        bars: [
            {
                variable: 'POORFAMMGMT',
                description: 'Low Parenting Structure',
                evalTag: 'poormgmt',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting poor family management.</p>
                        <p>
                            POORFAMMGMT = average of CATCHAL_RV, CATCHGN_RV, CATCHSK_RV, CLRRULE_RV, CMHOME_RV,
                            FAMRULE_RV, HMWORK_RV, and PARKNOW_RV where a higher score equals lower family management
                        </p>
                    </div>
                ),
            },
            {
                variable: 'FAMCONFLICT',
                description: 'Family Conflict',
                evalTag: 'famCon',
                tooltip: (
                    <div>
                        <p>
                            Reflects % of respondents that report their family yelling and arguing as a frequent
                            occurrence.
                        </p>
                        <p>
                            FAMCONFLICT = average of ARGUE, FAMYELL and SERARG where a higher score equals greater
                            family conflict
                        </p>
                    </div>
                ),
            },
            {
                variable: 'FAMUSEDRUGS',
                description: 'Family Substance Use',
                evalTag: 'famDrugs',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting their family members using drugs.</p>
                        <p>
                            FAMUSEDRUGS = average of FAMCIG, FAMHIGH, FAMMARIJ, and FAMUSE where a higher score equals
                            more drug use
                        </p>
                        <p>(Asked of High School Only)</p>
                    </div>
                ),
            },
            {
                variable: 'FAMCRIM',
                description: 'Family Crime',
                evalTag: 'famCrim',
                tooltip: (
                    <div>
                        <p>
                            Reflects % of respondents reporting their family members participating in illegal
                            activities.
                        </p>
                        <p>
                            FAMCRIM = average of FAMPOLIC and FAMSOLD where a higher score equals more illegal activity
                        </p>
                        <p>(Asked of High School Only)</p>
                    </div>
                ),
            },
            {
                variable: 'FAMANTISOC',
                description: 'Family Antisocial Behavior',
                evalTag: 'famAnti',
                tooltip: (
                    <div>
                        <p>
                            Reflects % of respondents that report their family members participating in antisocial
                            behavior.
                        </p>
                        <p>
                            FAMANTISOC = average of FAMCRIM and FAMUSEDDRUGS where a higher score equals greater
                            antisocial behavior
                        </p>
                        <p>(Asked of High School Only)</p>
                    </div>
                ),
            },
            {
                variable: 'FAMDRUGATT',
                description: 'Parental Attitudes Favoring Substance Use',
                evalTag: 'famDrugAtt',
                tooltip: (
                    <div>
                        <p>
                            Reflects % of respondents reporting a more favorable attitude from parents or guardians
                            about drug use.
                        </p>
                        <p>
                            FAMDRUGATT = average of WRPRALC, WRPRCIG, and WRPRMAR where a higher score equals a more
                            favorable attitude towards drug use
                        </p>
                    </div>
                ),
            },
            {
                variable: 'FAMANTIATT',
                description: 'Parental Attitudes Favoring Antisocial Behavior',
                evalTag: 'famAntiAtt',
                tooltip: (
                    <div>
                        <p>
                            Reflects % of respondents reporting a more favorable attitude from parents or guardians
                            about antisocial behavior.
                        </p>
                        <p>
                            FAMANTIATT = average of WRPRDRW, WRPRFGT, and WRPRSTL where a higher score equals a more
                            favorable attitude towards antisocial behavior
                        </p>
                    </div>
                ),
            },
        ],
    },
];
const familyMS = [
    {
        section: 'Family',
        color: '#D0E1F4',
        barColor: '#146AC6',
        bars: [
            {
                variable: 'POORFAMMGMT',
                description: 'Poor Family Management',
                evalTag: 'poormgmt',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting poor family management.</p>
                        <p>
                            POORFAMMGMT = average of CATCHAL_RV, CATCHGN_RV, CATCHSK_RV, CLRRULE_RV, CMHOME_RV,
                            FAMRULE_RV, HMWORK_RV, and PARKNOW_RV where a higher score equals lower family management
                        </p>
                    </div>
                ),
            },
            {
                variable: 'FAMCONFLICT',
                description: 'Family Conflict',
                evalTag: 'famCon',
                tooltip: (
                    <div>
                        <p>
                            Reflects % of respondents that report their family yelling and arguing as a frequent
                            occurrence.
                        </p>
                        <p>
                            FAMCONFLICT = average of ARGUE, FAMYELL and SERARG where a higher score equals greater
                            family conflict
                        </p>
                    </div>
                ),
            },
            {
                variable: 'FAMDRUGATT',
                description: 'Parental Attitudes Favoring Drug Use',
                evalTag: 'famDrugAtt',
                tooltip: (
                    <div>
                        <p>
                            Reflects % of respondents reporting a more favorable attitude from parents or guardians
                            about drug use.
                        </p>
                        <p>
                            FAMDRUGATT = average of WRPRALC, WRPRCIG, and WRPRMAR where a higher score equals a more
                            favorable attitude towards drug use
                        </p>
                    </div>
                ),
            },
            {
                variable: 'FAMANTIATT',
                description: 'Parental Attitudes Favoring Antisocial Behavior',
                evalTag: 'famAntiAtt',
                tooltip: (
                    <div>
                        <p>
                            Reflects % of respondents reporting a more favorable attitude from parents or guardians
                            about antisocial behavior.
                        </p>
                        <p>
                            FAMANTIATT = average of WRPRDRW, WRPRFGT, and WRPRSTL where a higher score equals a more
                            favorable attitude towards antisocial behavior
                        </p>
                    </div>
                ),
            },
        ],
    },
];

const individual = [
    {
        section: 'Individual',
        color: '#D0E1F4',
        barColor: '#146AC6',
        bars: [
            {
                variable: 'REBEL',
                description: 'Rebelliousness',
                evalTag: 'rebel',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting rebellious behavior.</p>
                        <p>
                            REBEL = average of AWYWTH, IGNORE and OPPOS where a higher score equals more rebellious
                            behavior
                        </p>
                    </div>
                ),
            },
            {
                variable: 'ANTISOCBEHONSET',
                description: 'Early Problem Behavior',
                evalTag: 'antisocbeh',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting signs of early problem behavior.</p>
                        <p>
                            ANTISOCBEHONSET = average of AGEARST_RC, AGEATT_RC, AGEGUN_RC, and AGESUS_RC where a higher
                            score equals later initiation
                        </p>
                    </div>
                ),
            },

            {
                variable: 'DRUGUSEONSET',
                description: 'Early Introduction to Substances',
                evalTag: 'drugonset',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting drug use at younger ages.</p>
                        <p>
                            DRUGUSEONSET = average of AGEALC_RC, AGEALCR_RC, AGECIG_RC, and AGEMAR_RC where a higher
                            score equals earlier initiation
                        </p>
                    </div>
                ),
            },
            {
                variable: 'DRUGRISKS',
                description: 'Low Perceived Risk of Substance Use',
                evalTag: 'drugrisk',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting low perceived risks of drug use.</p>
                        <p>
                            DRUGRISKS = average of HMALC, HMCIG, HMMARO, and HMMARR where a higher score equals a
                            greater perceived risk of using drugs
                        </p>
                    </div>
                ),
            },
            {
                variable: 'ANTISOCFRNDS',
                description: 'Friend Antisocial Behaviors',
                evalTag: 'antisocfriend',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting their friends participating in antisocial behavior.</p>
                        <p>
                            ANTISOCFRNDS = average of FRARST, FRDROP, FRGUN, FRSOLD, FRSTL, and FRSUS where a higher
                            score equals more antisocial friends
                        </p>
                    </div>
                ),
            },
            {
                variable: 'FRNDUSEDRUGS',
                description: 'Friend’s Use of Substances',
                evalTag: 'frienddrug',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting their friends’ drug use.</p>
                        <p>
                            FRNDUSEDRUGS = average of FRALC, FRCIG, FRDRUG, and FRMAR where a higher score equals more
                            friends who use drugs
                        </p>
                    </div>
                ),
            },
            {
                variable: 'FRGANG',
                description: 'Friend Gang Involvement',
                evalTag: 'friendgang',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting their friends’ gang involvement.</p>
                        <p>Variable = FRGANG</p>
                    </div>
                ),
            },
            {
                variable: 'AGGVICTIM',
                description: 'Victim of Repeated Aggression',
                evalTag: 'aggvic',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting bullying victimization.</p>
                        <p>
                            AGGVICTIM = average of HAPUCELL_RV, HAPUPUSH_RV, HAPURUMO_RV, and HAPUTEAS_RV where a higher
                            score equals greater bullying victimization
                        </p>
                    </div>
                ),
            },
        ],
    },
];
const individualOther = [
    {
        section: 'Individual',
        color: '#D0E1F4',
        barColor: '#146AC6',
        bars: [
            {
                variable: 'FRNDHITU_RC',
                description: 'Romantic Partner/Dating Violence',
                evalTag: 'friendvio',
                tooltip: (
                    <div>
                        <p>
                            Reflects % of respondents reporting physical violence from a romantic partner in the last
                            year.
                        </p>
                        <p>Variable = FRNDHITU_RC</p>
                        <p>(Asked of High School Only)</p>
                    </div>
                ),
            },
            {
                variable: 'WORKHR',
                description: 'Employment > 20 Hours Per Week',
                evalTag: 'work',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents that report working more than 20 hours a week.</p>
                        <p>Variable = WORKHR</p>
                        <p>(Asked of High School Only)</p>
                    </div>
                ),
            },
        ],
    },
];

const getVariables = array => {
    let variables = [];
    array.forEach(section => {
        if (section.subSections) {
            section.subSections.forEach(sub => {
                sub.bars.forEach(item => {
                    variables.push(item.variable);
                });
            });
        } else {
            section.bars.forEach(item => {
                variables.push(item.variable);
            });
        }
    });

    return variables;
};

const types = {
    community: {
        query: communityQuery,
        variables: getVariables(community),
    },
    school: {
        query: schoolQuery,
        variables: getVariables(school),
    },
    family: {
        query: familyQuery,
        variables: getVariables(family),
    },
    // old
    individual: {
        query: individualQuery,
        variables: getVariables(individual),
    },
    individualOther: {
        query: individualOtherQuery,
        variables: getVariables(individualOther),
    },
};

const RiskFactors = props => {
    // const { title, description } = props.settings;

    const [communityData, setCommunityData] = useState(null);
    const [schoolData, setSchoolData] = useState(null);
    const [familyData, setFamilyData] = useState(null);
    const [individualData, setIndividualData] = useState(null);
    const [otherIndividualData, setOtherIndividual] = useState(null);
    const isMSOnly = props.filters.level === 'middle_school';
    useEffect(() => {
        (async () => {
            const communityValues = await queryData(props.assignmentProgressIds, 'community');
            setCommunityData(communityValues);

            const schoolValues = await queryData(props.assignmentProgressIds, 'school');
            setSchoolData(schoolValues);

            const familyValues = await queryData(props.assignmentProgressIds, 'family');
            setFamilyData(familyValues);

            const individualValues = await queryData(props.assignmentProgressIds, 'individual');
            setIndividualData(individualValues);

            const otherIndividualValues = await queryData(props.assignmentProgressIds, 'individualOther');
            setOtherIndividual(otherIndividualValues);

            props.setWidgetsLoaded('riskFactors');
        })();
    }, [props.assignmentProgressIds]);

    const queryData = async (idList, type) => {
        const query = types[type].query;
        const variableList = types[type].variables;

        try {
            const response = await client.query({
                query,
                variables: {
                    idList,
                    variableList,
                },
            });

            if (response && response.data && !_isEmpty(response.data.flows.assignmentProgressSummary)) {
                return response.data.flows.assignmentProgressSummary;
            }
        } catch (e) {
            console.error(e);
            return null;
        }
    };

    const sections = isMSOnly
        ? [communityMS, school, familyMS, individual, individualOther]
        : [community, school, family, individual, individualOther];

    const tableData = formatTableSections(
        sections,
        [communityData, schoolData, familyData, individualData, otherIndividualData],
        ['Community', 'School', 'Family', 'Individual']
    );

    return (
        <>
            <div className={style.barChartWrapper}>
                <Page>
                    <Thumbs descending />
                    <ChartAxis type="top" />
                    {communityData ? (
                        <ChartSection sections={isMSOnly ? communityMS : community} data={communityData} />
                    ) : (
                        <div style={{ width: '99%', margin: 'auto' }}>
                            <Skeleton height={375} width="100%" style={{ marginBottom: '2em', marginTop: '40px' }} />
                        </div>
                    )}
                </Page>
                <Page>
                    <PrintOnly>
                        <Thumbs descending />
                        <ChartAxis type="top" />
                    </PrintOnly>
                    {schoolData ? (
                        <ChartSection sections={school} data={schoolData} />
                    ) : (
                        <div style={{ width: '99%', margin: 'auto' }}>
                            <Skeleton height={275} width="100%" style={{ marginBottom: '2em', marginTop: '20px' }} />
                        </div>
                    )}
                </Page>
                <Page>
                    <PrintOnly>
                        <Thumbs descending />
                        <ChartAxis type="top" />
                    </PrintOnly>
                    {familyData ? (
                        <ChartSection sections={isMSOnly ? familyMS : family} data={familyData} />
                    ) : (
                        <div style={{ width: '99%', margin: 'auto' }}>
                            <Skeleton height={500} width="100%" style={{ marginBottom: '2em', marginTop: '20px' }} />
                        </div>
                    )}
                </Page>
                <Page>
                    <PrintOnly>
                        <Thumbs descending />
                        <ChartAxis type="top" />
                    </PrintOnly>
                    {individualData && otherIndividualData ? (
                        <ChartSection
                            sections={
                                isMSOnly
                                    ? individual
                                    : [
                                          {
                                              section: 'Individual',
                                              color: '#D0E1F4',
                                              barColor: '#146AC6',
                                              bars: [...individual[0].bars, ...individualOther[0].bars],
                                          },
                                      ]
                            }
                            data={isMSOnly ? individualData : { ...individualData, ...otherIndividualData }}
                        />
                    ) : (
                        <div style={{ width: '99%', margin: 'auto' }}>
                            <Skeleton height={600} width="100%" style={{ marginBottom: '2em', marginTop: '20px' }} />
                        </div>
                    )}
                </Page>
            </div>
            <PrintOnly>
                <Page>
                    <PrintTable
                        {...tableData}
                        title="Risk Factors"
                        descending
                        tableHeaders={[
                            'Category',
                            'Item',
                            '# of Responses',
                            '# of Negative Responses',
                            '% of Negative Responses',
                        ]}
                    />
                </Page>
            </PrintOnly>
        </>
    );
};

RiskFactors.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    // redux
    assignmentProgressIds: PropTypes.array,
    setWidgetsLoaded: PropTypes.func,
};

// Filters are passed from reportTemplatePlayer HOC to the Report Player and Widgets
RiskFactors.defaultProps = {
    filters: {},
};

const mapStateToProps = state => ({
    assignmentProgressIds: state.app.report.assignmentProgressIds,
});

export default connect(mapStateToProps, { setWidgetsLoaded })(RiskFactors);
