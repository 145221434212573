import React from 'react';
import { Field, CccisdFieldWrapper } from 'cccisd-formik';
import OrgSelect from './filters/OrgSelect';
import LevelSelect from './filters/LevelSelect';
import SelectTree from './filters/SelectTree';
import MultiSelect from './filters/MultiSelect';
import CycleSelect from './filters/CycleSelect';
import DateSelect from './filters/DateSelect';
import InfoIcon from 'cccisd-icons/info2';
import Tooltip from 'cccisd-tooltip';
import style from './style.css';
// Define filters you want to use

export default [
    {
        name: 'organization',
        label: '',
        component: props => (
            <div>
                Organization:{' '}
                <Field name="organization">
                    {({ field, form }) => <OrgSelect {...props} field={field} form={form} />}
                </Field>
            </div>
        ),
    },
    {
        name: 'classList',
        label: '',
        component: props => {
            return (
                <div style={{ margin: 0 }}>
                    Site:
                    <Tooltip
                        title={
                            <div>
                                Here you can filter specific sites/schools and use the drop downs to filter the grade
                                levels of each site. Please click <strong>Done</strong> when you are finished.
                            </div>
                        }
                    >
                        <InfoIcon spaceLeft />
                    </Tooltip>
                    <Field name="classList">
                        {({ field, form }) => (
                            <CccisdFieldWrapper field={field} form={form}>
                                <SelectTree field={field} form={form} {...props} />
                            </CccisdFieldWrapper>
                        )}
                    </Field>
                </div>
            );
        },
        isRequired: false,
    },
    {
        name: 'level',
        label: '',
        component: props => (
            <div>
                School Level:
                <Field name="level">{({ field, form }) => <LevelSelect {...props} field={field} form={form} />}</Field>
            </div>
        ),
    },
    {
        name: 'cycles',
        label: '',
        component: props => (
            <div style={{ paddingTop: '21.5px', marginRight: '1em' }}>
                <Field name="cycles">{({ field, form }) => <CycleSelect {...props} field={field} form={form} />}</Field>
            </div>
        ),
    },
    {
        name: 'startDate',
        label: 'from',
        // initialValue: moment(new Date())
        //     .subtract(30, 'days')
        //     .format('YYYY-MM-DD'),

        component: () => (
            <div className={style.dateField}>
                From:
                <Field name="startDate">
                    {({ field, form }) => (
                        <CccisdFieldWrapper field={field} form={form}>
                            <DateSelect field={field} form={form} type="start" />
                        </CccisdFieldWrapper>
                    )}
                </Field>
            </div>
        ),
    },
    {
        name: 'endDate',
        label: '',
        // initialValue: moment(new Date()).format('YYYY-MM-DD'),
        component: () => (
            <div className={style.dateField}>
                To:
                <Field name="endDate">
                    {({ field, form }) => (
                        <CccisdFieldWrapper field={field} form={form}>
                            <DateSelect field={field} form={form} type="end" />
                        </CccisdFieldWrapper>
                    )}
                </Field>
            </div>
        ),
    },
    {
        name: 'validation',
        label: '',
        component: props => (
            <Field name="validation">{({ field, form }) => <MultiSelect field={field} form={form} {...props} />}</Field>
        ),
    },
];
