import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import { TextBlock, FieldBlock, domOnlyProps } from 'cccisd-redux-form-helpers';
import ClickButton from 'cccisd-click-button';
import Modal from 'cccisd-modal';
import Tooltip from 'cccisd-tooltip';
import style from './style.css';

export const fields = ['password', 'repeatPassword', 'termsandconds', 'acknowledgement'];

const validate = (values, props, state) => {
    const errors = {};

    if (!values.password) {
        errors.password = 'Password is required';
    } else if (values.password !== values.repeatPassword) {
        errors.repeatPassword = 'The passwords must match';
    }

    if (!values.termsandconds) {
        errors.termsandconds = 'The privacy policy and terms of use must be accepted to continue';
    }
    if (!values.acknowledgement) {
        errors.acknowledgement = 'You must accept the YYAES Acknowledgement and Undertakings to continue.';
    }

    return errors;
};

class Form extends React.Component {
    static propTypes = {
        fields: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        submitting: PropTypes.bool.isRequired,
        username: PropTypes.string,
        fullName: PropTypes.string,
    };

    state = {
        readDoc: false,
    };

    openModal = () => {
        this.modal.open();
        this.setState({ readDoc: true });
    };

    closeModal = () => {
        this.modal.close();
    };

    renderUserAgreement = fullName => {
        return (
            <div className={style.agreementText}>
                <p>
                    <u>
                        <strong>{fullName}</strong>
                    </u>{' '}
                    (acting through its agents, servants and employees, the “Applicant”) has requested that The Annie E.
                    Casey Foundation (the “Foundation”) make available to the Applicant the Youth Experience Survey, a
                    part of the Evidence2Success prevention framework, and a proprietary educational tool created and
                    administered by the Foundation primarily for the benefit of middle and high school educators and
                    their students (the “YES Survey” and the use of and the benefits to be derived therefrom,
                    collectively, the “YES Survey Program” ), and the Foundation has agreed to do so upon the terms,
                    conditions and provisions which follow (collectively, the “Applicant’s Undertakings”).
                </p>
                <p>
                    In consideration of the benefits to be afforded to the Applicant by virtue of the Applicant’s
                    participation in the YES Survey Program, and with the intention of being legally bound hereby, the
                    Applicant represents, warrants, covenants and agrees to each and all of the following for the
                    benefit of the Foundation:
                </p>

                <ul>
                    <li>
                        The Applicant has received and carefully reviewed a copy of each of the YES Survey and the
                        Foundation’s Frequently Asked Questions About the Youth Experience Survey.
                    </li>
                    <li>
                        The Applicant acknowledges and agrees that the success of the YES Survey and the YES Survey
                        Program is expressly contingent up the maintenance of the confidentiality of the individual
                        responses to the YES Survey and in connection there has agreed to instruct all teachers and
                        others who administer the YES Survey, or otherwise participate in the YES Survey Program, to:
                        (i) instruct the students being surveyed not to include any personal identifying information on
                        their YES Survey responses; and (ii) neither review nor disclose to any person (inducing, but
                        not limited to, guidance counselors, law enforcement or other governmental authorities, parents
                        or other caretakers) whatsoever the contents of any completed YES Survey and upon completion to
                        return securely the reviewed YES Surveys to the Foundation for aggregate data processing and
                        analysis (collectively the “Confidentiality Undertakings”).
                    </li>
                    <li>
                        The Applicant further acknowledges and agrees that: (i) for the anonymity of individual students
                        taking the YES Survey, the results will be always be presented as an aggregate of at least ten
                        (10) respondents, never for an individual; and (ii) the Foundation may use the data collected to
                        make improvements to the YES Survey, to calculate national/regional norms, or to present
                        information about the YES Survey and YES Survey at Foundation or educational forums; provided,
                        however that the {`Applicant's`} specific community will never be identified and data will
                        always be presented anonymously and in aggregate (collectively, the “Foundation Undertakings”).
                    </li>
                    <li>
                        Before administering the YES Survey to any student: (i) the student shall be advised of (a) the
                        Confidentiality Undertakings and the Foundation Undertakings, and (b) the student may opt out of
                        participating in the Survey without penalty of any kind; and (ii) the parent or other authorized
                        caregiver having previous been advised of the Confidentiality Undertakings and the Foundation
                        Undertakings shall have consented in writing to such student’s participation in the YES Survey
                        Program, consistent with all applicable laws rules and regulations in connection therewith
                        (collectively, the “Consents”) and shall securely maintain such Consents for review upon request
                        of the Foundation for a period of no less than three (3) years following such student’s
                        submission of the completed YES Survey.
                    </li>
                    <li>
                        The failure of the Applicant to strictly comply with the Confidentiality Undertakings or to
                        procure and maintain the Consents will cause immediate and irreparable harm to the reputation
                        and standing of the Foundation as a leader in this field and in which instance the Applicant
                        agrees: (i) that the Foundation shall be entitled to injunctive or other equitable relief from
                        the Applicant without the necessity of demonstrating an inadequate remedy at law or the
                        necessity to post a bond; and (ii) to indemnify and hold harmless the Foundation and its
                        Trustees, Officers, agents, servant and employees (collectively, the “Foundation Parties”) from
                        and against any all damages suffered by any of the Foundation Parties.
                    </li>
                </ul>
            </div>
        );
    };

    render() {
        const {
            fields: {
                password,
                repeatPassword,
                termsandconds,
                acknowledgement,
                // , e2s1, e2s2, e2s3, e2s4
            },
            handleSubmit,
            submitting,
            username,
            fullName,
        } = this.props;

        return (
            <>
                <form onSubmit={handleSubmit}>
                    <h3>Welcome, {fullName || username}!</h3>
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <span style={{ fontSize: '20px' }}>
                                Create a password for your account with username <strong>{username}</strong>
                            </span>
                        </div>
                        <div className="panel-body">
                            <div style={{ maxWidth: '350px' }}>
                                <TextBlock field={password} label="Password" type="password" />
                            </div>
                            <div style={{ maxWidth: '350px' }}>
                                <TextBlock field={repeatPassword} label="Confirm Password" type="password" />
                            </div>
                        </div>
                    </div>
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                3C Privacy Policy and Terms of Use
                            </span>
                        </div>
                        <div className="panel-body">
                            <div className={style.terms}>
                                <ul>
                                    <li>
                                        <a href="https://www.3cisd.com/privacy-policy?page_id=3110" target="blank">
                                            Privacy Policy
                                        </a>
                                    </li>
                                    <li>
                                        <a href="http://www.3cisd.com/terms-of-use" target="blank">
                                            Terms of Use
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="panel-footer">
                            <FieldBlock field={termsandconds}>
                                <div className="checkbox">
                                    <label>
                                        <input type="checkbox" {...domOnlyProps(termsandconds)} />I accept the privacy
                                        policy and terms of use.
                                    </label>
                                </div>
                            </FieldBlock>
                        </div>
                    </div>
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <span style={{ fontSize: '20px', fontWeight: 'bold' }}>YYAES Acknowledgement</span>
                        </div>
                        <div className="panel-body">
                            <div className={style.terms}>
                                <ul>
                                    <li>
                                        <Modal
                                            ref={elem => {
                                                this.modal = elem;
                                            }}
                                            title="Youth Experience Survey Acknowledgement and Undertakings"
                                            modalClassName="modalClass"
                                            trigger={
                                                <Tooltip title="You must click on the YYAES Acknowledgement and Undertakings link before you can accept.">
                                                    <a href="#" onClick={this.openModal}>
                                                        Youth Experience Survey Acknowledgement and Undertakings
                                                    </a>
                                                </Tooltip>
                                            }
                                        >
                                            <div className={style.scrollBox} onScroll={this.handleScroll}>
                                                {this.renderUserAgreement(fullName)}
                                            </div>

                                            <div className={style.buttonWrapper}>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={this.closeModal}
                                                >
                                                    Continue
                                                </button>
                                            </div>
                                        </Modal>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="panel-footer">
                            <FieldBlock field={acknowledgement} placement="left">
                                <Tooltip title="You must accept the YYAES Acknowledgment and Undertakings before you can activate your account.">
                                    <div className="checkbox">
                                        <label>
                                            <input
                                                type="checkbox"
                                                {...domOnlyProps(acknowledgement)}
                                                disabled={!this.state.readDoc}
                                            />
                                            I accept Youth Experience Survey Acknowledgement and Undertakings
                                        </label>
                                    </div>
                                </Tooltip>
                            </FieldBlock>
                        </div>
                    </div>

                    <ClickButton
                        className="btn btn-primary"
                        title="Activate"
                        isLoading={submitting}
                        onClick={handleSubmit}
                    />
                </form>
            </>
        );
    }
}

export default reduxForm({
    fields,
    initialValues: {
        password: '',
        repeatPassword: '',
        termsandconds: false,
        // e2s1: false,
        // e2s2: false,
        // e2s3: false,
        // e2s4: false,
    },
    validate,
})(Form);
