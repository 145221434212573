import React from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { CccisdSelect } from 'cccisd-formik';
import { updateSchoolLevel } from 'js/reducers/report.js';

const LevelSelect = props => {
    const dispatch = useDispatch();

    return (
        <CccisdSelect
            field={props.field}
            form={props.form}
            {...props.settings}
            options={[
                { value: 'aggregate', label: 'Aggregate' },
                { value: 'ms', label: 'Middle School' },
                { value: 'hs', label: 'High School' },
            ]}
            onChange={e => {
                props.form.handleChange(e);
                dispatch(updateSchoolLevel(e.target.value));
            }}
        />
    );
};

LevelSelect.propTypes = {
    form: PropTypes.object,
    field: PropTypes.object,
};

const mapStateToProps = state => ({
    orgList: state.app.report.orgList,
});

export default connect(mapStateToProps, { updateSchoolLevel })(LevelSelect);
