import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'outcomes',
    label: 'Outcomes',
    builderInitialValues,
    builder: Builder,
    player: Player,
};
