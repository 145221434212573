import React, { useState, useEffect } from 'react';
import axios from 'cccisd-axios';
import _format from 'date-fns/format';

const Boilerplate = window.cccisd.boilerplate;

const ViewDocs = ({ value, row, loadData, settings, isCsv }) => {
    const [docPath, setDocPath] = useState(null);
    const [docDate, setDocDate] = useState(null);

    useEffect(() => {
        setDocPath(row[settings.doc_variable]);
        let dateUploaded = row[settings.doc_date] ? _format(new Date(row[settings.doc_date]), 'MM-DD-YYYY hh:mm') : '';
        setDocDate(dateUploaded);
    });

    const downloadFile = async token => {
        const response = await axios.post(Boilerplate.route('flow.api.entity.showFile'), {
            download: 0,
            filename: docPath,
        });

        window.open(response.data.data.url, '_blank');
    };

    return docPath ? (
        <div className="text-center">
            <button type="button" className="btn btn-primary btn-sm" onClick={() => downloadFile(docPath)}>
                View Doc
            </button>
            <p>{docDate} </p>
        </div>
    ) : null;
};

export default (settings, callbackHandler) => {
    return props => <ViewDocs {...props} settings={settings} />;
};
