import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from './DatePicker.js';

const DateSelect = props => {
    const type = props.type;
    return <DatePicker field={props.field} form={props.form} {...props.settings} type={type} />;
};

DateSelect.propTypes = {
    form: PropTypes.object,
    field: PropTypes.object,
};

export default DateSelect;
