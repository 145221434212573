import React from 'react';
import ArrowRight from 'cccisd-icons/arrow-right5';
import ArrowDown from 'cccisd-icons/arrow-down5';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { setShowAdvanced } from 'js/reducers/report';
const AdvancedBlock = props => {
    const dispatch = useDispatch();
    const { showAdvanced, children } = props;

    return (
        <>
            <div
                style={{ display: 'flex', paddingBottom: showAdvanced ? 0 : '1em' }}
                onClick={() => {
                    dispatch(setShowAdvanced(!showAdvanced));
                }}
            >
                {showAdvanced ? <ArrowDown /> : <ArrowRight />} ADVANCED FILTERS
            </div>
            <div style={showAdvanced ? { display: 'block' } : { display: 'none' }}>{children}</div>{' '}
        </>
    );
};

AdvancedBlock.propTypes = {
    form: PropTypes.object,
    field: PropTypes.object,
    // from redux
    showAdvanced: PropTypes.bool,
};

const mapStateToProps = state => ({
    showAdvanced: state.app.report.showAdvanced,
});

export default connect(mapStateToProps, { setShowAdvanced })(AdvancedBlock);
