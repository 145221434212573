import React from 'react';
import { ResponsivePie } from 'cccisd-nivo/pie';
import style from './style.css';

const PieExample = props => {
    const { respondentCount, arr, tagWidth } = props;

    const chartData = arr.map(item => {
        const sectionPercentage = () => {
            if (respondentCount === 0) {
                return 0;
            }
            return (item.value / respondentCount) * 100;
        };
        const rounded = Math.round(sectionPercentage() * 10) / 10;

        return {
            ...item,
            value: rounded,
        };
    });

    return (
        <div>
            <div style={{ width: '100%', height: '325px', margin: 'auto' }}>
                <ResponsivePie
                    data={chartData}
                    margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                    enableArcLinkLabels={false}
                    width={350}
                    arcLabel={({ value }) => `${value}%`}
                    arcLabelsTextColor={({ data: { textColor } }) => textColor}
                    theme={{
                        labels: {
                            text: { fontSize: '16px' },
                        },
                        legends: {
                            text: {
                                fontSize: '16px',
                            },
                        },
                    }}
                    colors={properties => properties.data.color}
                    tooltip={({ datum }) => {
                        return (
                            <span className={style.chartTooltip}>
                                <span
                                    className={style.toolTipLabel}
                                    style={{
                                        color: `${datum.color}`,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {datum.label}:{' '}
                                </span>
                                {datum.formattedValue}%
                            </span>
                        );
                    }}
                />
            </div>
            <div className={style.legend}>
                {arr.map((item, i) => {
                    const match = chartData.find(thing => thing.id === item.id);
                    return (
                        <div className={style.legendTag} style={tagWidth} key={i}>
                            <div
                                className={style.colorSwatch}
                                style={{
                                    backgroundColor: `${item.color}`,
                                    color: `${item.textColor}`,
                                }}
                            >
                                {match.value}%
                            </div>
                            <div>{item.label}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default PieExample;
