import React from 'react';
import Header from 'cccisd-header';
import style from './style.css';

const Fortress = window.cccisd.fortress;

const AppHeader = () => {
    const defaultLinks = [
        { url: `/dashboard`, label: 'Dashboard' },
        { url: `/resourcecenter/`, label: 'Resources' },
        { url: `/manage`, label: 'Manage' },
    ];

    const orgAdminLinks = [
        { url: `/dashboard`, label: 'Dashboard' },
        { url: `/deploymentDocuments`, label: 'Deployment Documents' },
        { url: `/resourcecenter`, label: 'Resources' },
        { url: `/manage`, label: 'Manage' },
        { url: `/quest`, label: 'Quest' },
    ];

    let links = defaultLinks;

    if (Fortress.user.acting.data_type === 'orgAdmin') {
        links = orgAdminLinks;
    }

    if (!Fortress.auth()) {
        links = [];
    }
    return (
        <Header
            primaryNavLinks={links}
            containerClassName="container-fluid"
            wrapperClassName={style.wrapper}
            showHomeAction={false}
            showAlertAction={false}
            logoClassName={style.logo}
        />
    );
};

export default AppHeader;
