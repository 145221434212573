import React from 'react';
import PropTypes from 'prop-types';
import Form from './form.js';
import axios from 'cccisd-axios';

const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;

export default class ActivationForm extends React.Component {
    static propTypes = {
        onSuccess: PropTypes.func,
        formName: PropTypes.string,
        match: PropTypes.object.isRequired,
    };

    state = {
        username: null,
        fullName: null,
    };

    componentDidMount = async () => {
        const activationToken = this.props.match.params.activationHash;

        const response = await axios.get(
            Boilerplate.route('api.nexus.user.verifyActivationUser', {
                activation_token: activationToken,
            })
        );

        if (response.data.status === 'success') {
            if (response.data.data.activated_at) {
                // user is already activated.
                window.location = Boilerplate.url('authRedirect');
            }

            this.setState({
                username: response.data.data.username,
                fullName: response.data.data.full_name,
            });
        } else {
            window.location = '/';
            throw response.data.errors;
        }
    };

    _onSubmit = async values => {
        values.activationToken = this.props.match.params.activationHash;
        values.login = 1; // also login after activation

        const response = await axios.post(Boilerplate.route('api.nexus.user.activate'), values);
        if (response.data.status === 'success') {
            if (this.props.onSuccess) {
                await this.props.onSuccess(response);
            }
            let redirectUrl = Boilerplate.url(Fortress.settings.config.after_login_url);
            if (response.data.data.hasIncompleteSiteRegistration) {
                redirectUrl = Boilerplate.url('/account/registrationSurvey');
            }
            window.location = redirectUrl;
        } else {
            throw response.data.errors;
        }
    };

    render() {
        return (
            <Form
                onSubmit={this._onSubmit}
                form={this.props.formName || 'activationForm'}
                username={this.state.username}
                fullName={this.state.fullName}
            />
        );
    }
}
