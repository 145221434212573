import React from 'react';
import style from './style.css';
import AdvancedBlock from '../components/AdvancedBlock';
import { connect, useDispatch } from 'react-redux';
import { setFilterHack } from 'js/reducers/report.js';
import Loader from 'cccisd-loader';
const Fortress = window.cccisd.fortress;
const userRole = Fortress.user.acting.data_type;
const isAdmin = userRole === 'uberadmin' || userRole === 'orgAdmin';

const FilterBlock = props => {
    const dispatch = useDispatch();

    const standardFilters = props.actualFields.filter(
        item => item.name !== 'validation' && item.name !== 'startDate' && item.name !== 'endDate'
    );

    const advancedFilters = props.actualFields.filter(
        item => item.name === 'validation' || item.name === 'startDate' || item.name === 'endDate'
    );

    const renderButtonContents = () => {
        if (props.listLoading) {
            return (
                <div className={style.loadingButton}>
                    <span>Loading Filters</span> <Loader loading type="inline" />
                </div>
            );
        }
        if (props.valueChanged || props.filterHack) {
            return 'Apply Filters';
        }
        return 'Filters Applied';
    };

    return (
        <div className={style.form}>
            <div className={style.fields}>
                {standardFilters.map(f => (
                    <f.component
                        key={f.name}
                        settings={f}
                        reportHandle={props.reportHandle}
                        userType={props.userType}
                    />
                ))}
                <div className="form-group" style={{ paddingTop: '22.5px' }}>
                    <>
                        <button
                            className={`btn ${
                                (props.valueChanged && !props.listLoading) ||
                                (!props.valueChanged && props.filterHack && !props.listLoading)
                                    ? 'btn-warning'
                                    : 'btn-default ' + style.disabledBtn
                            }`}
                            type="submit"
                            disabled={(!props.valueChanged && !props.filterHack) || props.listLoading}
                            onClick={() => {
                                dispatch(setFilterHack(false));
                            }}
                        >
                            {renderButtonContents()}
                        </button>
                    </>
                </div>
            </div>
            <div style={isAdmin ? {} : { visibility: 'hidden', height: '0' }}>
                <AdvancedBlock>
                    <div className={style.advanced}>
                        {advancedFilters.map(f => (
                            <f.component
                                key={f.name}
                                settings={f}
                                reportHandle={props.reportHandle}
                                userType={props.userType}
                            />
                        ))}
                    </div>
                </AdvancedBlock>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    listLoading: state.app.report.listLoading,
    filterHack: state.app.report.filterHack,
});

export default connect(mapStateToProps, { setFilterHack })(FilterBlock);
