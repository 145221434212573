import React from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { CccisdSelect } from 'cccisd-formik';
import { updateOrganization } from 'js/reducers/report.js';

const OrgSelect = props => {
    const dispatch = useDispatch();

    return (
        <CccisdSelect
            field={props.field}
            form={props.form}
            {...props.settings}
            options={props.orgList.map(item => {
                return { value: item.groupId, label: item.label };
            })}
            onChange={e => {
                props.form.handleChange(e);
                const org = props.orgList.find(item => item.groupId === +e.target.value);
                dispatch(updateOrganization(org));
            }}
        />
    );
};

OrgSelect.propTypes = {
    form: PropTypes.object,
    field: PropTypes.object,
    // from redux
    orgList: PropTypes.array,
};

const mapStateToProps = state => ({
    orgList: state.app.report.orgList,
});

export default connect(mapStateToProps, { updateOrganization })(OrgSelect);
