import thumbnail from './withModules.png';
import Settings from './Settings';
import Mediator from './Mediator';

export default {
    handle: 'module',
    label: 'With Modules',
    thumbnail,
    settingsComponent: Settings,
    mediatorComponent: Mediator,
};
