export const formatTableSections = (sections, data, key) => {
    let allData = {};
    data.forEach(item => {
        allData = {
            ...allData,
            ...item,
        };
    });

    const allSections = sections.flat();

    const sectionData = key.map(sec => {
        const matchingSections = allSections.filter(item => item.section === sec);
        matchingSections;

        if (matchingSections.length > 1) {
            const matchingRows = matchingSections.map(match => {
                if (match.subSections) {
                    const bars = match.subSections.map(item => {
                        return { section: item.section, rows: item.bars };
                    });

                    return bars;
                }

                return { section: match.section, rows: match.bars };
            });

            return { section: sec, rowSections: matchingRows };
        }
        return {
            section: sec,
            rows: matchingSections[0].bars,
        };
    });

    return { sectionData, allData };
};
