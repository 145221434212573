import React from 'react';
import PropTypes from 'prop-types';
import notification from 'cccisd-notification';
import Player from '../../vendor/reports/reportTemplates/Dashboard/Player';
import style from './style.css';
const Appdefs = window.cccisd.appDefs;

const ReportRender = props => {
    const { handle } = props;
    const settings =
        Appdefs &&
        Appdefs.reports &&
        Appdefs.reports.list &&
        Appdefs.reports.list.find(report => report.id === 'dashboard');

    if (!settings) {
        notification({
            message: `Cannot find a report where field "id" is equal to "${handle}"`,
            type: 'danger',
        });
        return null;
    }

    return (
        <div className={`playerWrapper ${style.playerWrapper}`}>
            <h1>Dashboard</h1>
            <p className={style.description}>
                The YES Dashboard provides a snapshot of YES survey responses from participating
                youth and young adults. Data displayed on the dashboard is “real-time” and based on
                selected characteristics, or filters. Stakeholders may use this data to support
                local decision making and to drive discussion regarding the development of
                community-based programming to meet the needs and expectations of youth and young
                adults.
            </p>
            <div className={style.instructions}>
                <h3>Dashboard Filters</h3>
                <div>
                    Click on the drop downs below to filter user data, then click{' '}
                    <span>Apply Filters</span>
                </div>
            </div>
            <Player settings={settings.reportTemplateSettings} isPreview={false} {...props} />
        </div>
    );
};

ReportRender.propTypes = {
    handle: PropTypes.string,
    deploymentId: PropTypes.number,
    startNewSurvey: PropTypes.func,
};

export default ReportRender;
