import React from 'react';
import style from './style.css';

const DemographicsTable = props => {
    const {
        respondentCount,
        tableData: { age, gender, ethnicity, livingSituation, householdMembers, language, youthSys },
    } = props;

    const getPercentage = num => {
        const sectionPercentage = () => {
            if (respondentCount === 0) {
                return 0;
            }
            return (num / respondentCount) * 100;
        };
        const rounded = Math.round(sectionPercentage() * 10) / 10;
        return `${rounded}%`;
    };

    return (
        <table className="table table-bordered table-striped">
            <thead>
                <tr>
                    <th colSpan={4} className={style.tableTitle}>
                        <div>
                            <h3>Demographics</h3> <h5>{`(Total # of Responses = ${respondentCount})`}</h5>{' '}
                        </div>
                    </th>
                </tr>
                <tr>
                    <th className="text-center">Category</th>
                    <th className="text-center">Response Options</th>
                    <th className="text-center"># of Responses</th>
                    <th className="text-center">Percentage of Responses</th>
                </tr>
            </thead>
            <tbody>
                {age.map((item, i) => {
                    if (i === 0) {
                        return (
                            <tr key={i}>
                                <td rowSpan={age.length}>Age</td>
                                <td>{item.id}</td>
                                <td className="text-center">{item.count}</td>
                                <td className="text-center">{getPercentage(item.count)}</td>
                            </tr>
                        );
                    }
                    return (
                        <tr key={i}>
                            <td>{item.id}</td>
                            <td className="text-center">{item.count}</td>
                            <td className="text-center">{getPercentage(item.count)}</td>
                        </tr>
                    );
                })}
                {gender.map((item, i) => {
                    if (i === 0) {
                        return (
                            <tr key={i}>
                                <td rowSpan={gender.length}>Gender</td>
                                <td>{item.label}</td>
                                <td className="text-center">{item.value}</td>
                                <td className="text-center">{getPercentage(item.value)}</td>
                            </tr>
                        );
                    }
                    return (
                        <tr key={i}>
                            <td>{item.label}</td>
                            <td className="text-center">{item.value}</td>
                            <td className="text-center">{getPercentage(item.value)}</td>
                        </tr>
                    );
                })}
                {livingSituation.map((item, i) => {
                    if (i === 0) {
                        return (
                            <tr key={i}>
                                <td rowSpan={livingSituation.length}>Living Situation</td>
                                <td>{item.label}</td>
                                <td className="text-center">{item.value}</td>
                                <td className="text-center">{getPercentage(item.value)}</td>
                            </tr>
                        );
                    }
                    return (
                        <tr key={i}>
                            <td>{item.label}</td>
                            <td className="text-center">{item.value}</td>
                            <td className="text-center">{getPercentage(item.value)}</td>
                        </tr>
                    );
                })}
                {ethnicity.map((item, i) => {
                    if (i === 0) {
                        return (
                            <tr key={i}>
                                <td rowSpan={ethnicity.length}>Ethnicity</td>
                                <td>{item.label}</td>
                                <td className="text-center">{item.value}</td>
                                <td className="text-center">{getPercentage(item.value)}</td>
                            </tr>
                        );
                    }
                    return (
                        <tr key={i}>
                            <td>{item.label}</td>
                            <td className="text-center">{item.value}</td>
                            <td className="text-center">{getPercentage(item.value)}</td>
                        </tr>
                    );
                })}
                {householdMembers.map((item, i) => {
                    if (i === 0) {
                        return (
                            <tr key={i}>
                                <td rowSpan={householdMembers.length}>Lives with</td>
                                <td>{item.label}</td>
                                <td className="text-center">{item.value}</td>
                                <td className="text-center">{getPercentage(item.value)}</td>
                            </tr>
                        );
                    }
                    return (
                        <tr key={i}>
                            <td>{item.label}</td>
                            <td className="text-center">{item.value}</td>
                            <td className="text-center">{getPercentage(item.value)}</td>
                        </tr>
                    );
                })}
                {language.map((item, i) => {
                    if (i === 0) {
                        return (
                            <tr key={i}>
                                <td rowSpan={language.length}>Language at home</td>
                                <td>{item.id}</td>
                                <td className="text-center">{item.count}</td>
                                <td className="text-center">{getPercentage(item.count)}</td>
                            </tr>
                        );
                    }
                    return (
                        <tr key={i}>
                            <td>{item.id}</td>
                            <td className="text-center">{item.count}</td>
                            <td className="text-center">{getPercentage(item.count)}</td>
                        </tr>
                    );
                })}
                {youthSys.map((item, i) => {
                    if (i === 0) {
                        return (
                            <tr key={i}>
                                <td rowSpan={2}>Youth System Involvement</td>
                                <td>{item.label}</td>
                                <td className="text-center">{item.value}</td>
                                <td className="text-center">{getPercentage(item.value)}</td>
                            </tr>
                        );
                    }
                    return (
                        <tr key={i}>
                            <td>{item.label}</td>
                            <td className="text-center">{item.value}</td>
                            <td className="text-center">{getPercentage(item.value)}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default DemographicsTable;
