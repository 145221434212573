export const getDemographics1 = (data, type) => {
    const dataTypes = {
        age: [
            { section: 'age', id: '10', count: 0, color: '#F8B6FF', textColor: 'black' },
            { section: 'age', id: '11', count: 0, color: '#72C9BC', textColor: 'black' },
            { section: 'age', id: '12', count: 0, color: '#CF83D6', textColor: 'black' },
            { section: 'age', id: '13', count: 0, color: '#4EB1F4', textColor: 'black' },
            { section: 'age', id: '14', count: 0, color: '#5D9D65', textColor: 'black' },
            { section: 'age', id: '15', count: 0, color: '#A650AD', textColor: '#fff' },
            { section: 'age', id: '16', count: 0, color: '#2B66CB', textColor: '#fff' },
            { section: 'age', id: '17', count: 0, color: '#47700C', textColor: '#fff' },
            { section: 'age', id: '18', count: 0, color: '#1d33ad', textColor: '#fff' },
            { section: 'age', id: '19', count: 0, color: `#07005e`, textColor: '#fff' },
        ],
        language: [
            { section: 'gender', id: 'English', count: 0, color: '#47700C', textColor: '#fff' },
            { section: 'gender', id: 'Spanish', count: 0, color: '#2a66cb', textColor: '#fff' },
            { section: 'gender', id: 'Other', count: 0, color: '#0D0D0D', textColor: '#fff' },
        ],
    };

    const typeArr = dataTypes[type];

    const values = data.map(item => {
        if (type === 'language') {
            if (!item.devTags.HouseLanguage) {
                return 'Other';
            }
            return typeArr[+item.devTags.HouseLanguage - 1].id;
        }
        if (!item.devTags.Age) {
            return null;
        }
        return item.devTags.Age;
    });

    values.forEach(item => {
        const match = typeArr.find(obj => obj.id === item);
        if (match) {
            typeArr[typeArr.indexOf(match)].count += 1;
        }
    });

    return typeArr;
};
export const getDemographics2 = (data, type) => {
    const dataTypes = {
        gender: [
            {
                section: 'gender',
                id: '2',
                label: 'Male',
                value: 0,
                color: '#5D9D65',
                textColor: '#000000',
            },
            {
                section: 'gender',
                id: '1',
                label: 'Female',
                value: 0,
                color: '#4EB1F4',
                textColor: '#000000',
            },
            {
                section: 'gender',
                id: '3',
                label: 'Non-binary',
                value: 0,
                color: '#A650AD',
                textColor: '#ffffff',
            },
            {
                section: 'gender',
                id: '4',
                label: 'Self-identify',
                value: 0,
                color: '#2a66cb',
                textColor: '#ffffff',
            },
            {
                section: 'gender',
                id: '5',
                label: 'No Answer',
                value: 0,
                color: '#0D0D0D',
                textColor: '#ffffff',
            },
        ],
        ethnicity: [
            {
                section: 'ethnicity',
                id: '1',
                label: 'White',
                value: 0,
                color: '#F8B6FF',
                textColor: '#000000',
            },
            {
                section: 'ethnicity',
                id: '2',
                label: 'Black',
                value: 0,
                color: '#72C9BC',
                textColor: '#000000',
            },
            {
                section: 'ethnicity',
                id: '3',
                label: 'Hispanic / Latino',
                value: 0,
                color: '#CF83D6',
                textColor: '#000000',
            },
            {
                section: 'ethnicity',
                id: '4',
                label: 'Native American',
                value: 0,
                color: '#4EB1F4',
                textColor: '#000000',
            },
            {
                section: 'ethnicity',
                id: '5',
                label: 'Asian',
                value: 0,
                color: '#5D9D65',
                textColor: '#000000',
            },
            {
                section: 'ethnicity',
                id: '6',
                label: 'Native Hawaiian',
                value: 0,
                color: '#A650AD',
                textColor: '#ffffff',
            },
            {
                section: 'ethnicity',
                id: '7',
                label: 'Multiracial',
                value: 0,
                color: '#2B66CB',
                textColor: '#ffffff',
            },
            {
                section: 'ethnicity',
                id: '8',
                label: 'Other',
                value: 0,
                color: '#0D0D0D',
                textColor: '#ffffff',
            },
        ],
        livingSituation: [
            {
                section: 'livingSituation',
                id: 'House / Apartment / Condo',
                label: 'House / Apartment / Condo',
                value: 0,
                color: '#CF83D6',
                textColor: '#000000',
            },
            {
                section: 'livingSituation',
                id: 'Residential or Group Home',
                label: 'Residential or Group Home',
                value: 0,
                color: '#4EB1F4',
                textColor: '#000000',
            },
            {
                section: 'livingSituation',
                id: 'Moving around frequently',
                label: 'Moving around frequently',
                value: 0,
                color: '#5D9D65',
                textColor: '#000000',
            },
            {
                section: 'livingSituation',
                id: 'Out of car / On street',
                label: 'Out of car / On street',
                value: 0,
                color: '#1D33AD',
                textColor: '#ffffff',
            },
            {
                section: 'livingSituation',
                id: 'Other',
                label: 'Other',
                value: 0,
                color: '#0D0D0D',
                textColor: '#ffffff',
            },
        ],
        householdMembers: [
            {
                section: 'householdMembers',
                id: 'Mother',
                label: 'Mother',
                value: 0,
                color: '#F8B6FF',
                textColor: '#000000',
                devTag: 'LivesMom',
            },
            {
                section: 'householdMembers',
                id: 'StepMother',
                label: 'StepMother',
                value: 0,
                color: '#72C9BC',
                textColor: '#000000',
                devTag: 'LivesSMom',
            },
            {
                section: 'householdMembers',
                id: 'Father',
                label: 'Father',
                value: 0,
                color: '#CF83D6',
                textColor: '#000000',
                devTag: 'LivesDad',
            },
            {
                section: 'householdMembers',
                id: 'Stepfather',
                label: 'Stepfather',
                value: 0,
                color: '#4EB1F4',
                textColor: '#000000',
                devTag: 'LivesSDad',
            },
            {
                section: 'householdMembers',
                id: 'Grandmother',
                label: 'Grandmother',
                value: 0,
                color: '#5D9D65',
                textColor: '#000000',
                devTag: 'LivesGMom',
            },
            {
                section: 'householdMembers',
                id: 'Grandfather',
                label: 'Grandfather',
                value: 0,
                color: '#A650AD',
                textColor: '#ffffff',
                devTag: 'LivesGDad',
            },
            {
                section: 'householdMembers',
                id: 'Aunt(s) / Uncle(s)',
                label: 'Aunt(s) / Uncle(s)',
                value: 0,
                color: '#2B66CB',
                textColor: '#ffffff',
                devTag: 'LivesAu',
            },
            {
                section: 'householdMembers',
                id: 'Sibling(s)',
                label: 'Sibling(s)',
                value: 0,
                color: '#47700C',
                textColor: '#ffffff',
                devTag: 'LivesSIB',
            },
            {
                section: 'householdMembers',
                id: 'Other',
                label: 'Other',
                value: 0,
                color: '#06005E',
                textColor: '#ffffff',
                devTag: 'LivesOther',
            },
        ],
    };

    const typeArr = [...dataTypes[type]];

    const values = data.map(item => {
        const { devTags } = item;
        if (type === 'gender') {
            if (!devTags.Gender) {
                return '5';
            }
            return devTags.Gender;
        }
        if (type === 'ethnicity') {
            if (!devTags.Race) {
                return '8';
            }
            return devTags.Race;
        }
        if (type === 'livingSituation') {
            if (!devTags.LivingSit) {
                return '5';
            }
            return devTags.LivingSit;
        }

        return { devTags };
    });

    values.forEach(item => {
        if (type === 'gender' || type === 'ethnicity') {
            const match = typeArr.find(obj => obj.id === item);

            if (match) {
                typeArr[typeArr.indexOf(match)].value += 1;
            }
        } else if (type === 'livingSituation') {
            typeArr[+item - 1].value += 1;
        } else {
            typeArr.forEach(obj => {
                const { devTags } = item;

                if (+devTags[obj.devTag]) {
                    typeArr[typeArr.indexOf(obj)].value += 1;
                }
            });
        }
    });

    return typeArr;
};

export const getYouthSysData = data => {
    const juvyCount = data.filter(item => +item.devTags.JuvyCenter).length;
    const fosterCount = data.filter(item => +item.devTags.FosterCare).length;

    return [
        { label: 'Foster Care', value: fosterCount },
        { label: 'Juvenile Detention', value: juvyCount },
    ];
};
