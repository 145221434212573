import React, { useState, useEffect } from 'react';
import Modal from 'cccisd-modal';
import Tooltip from 'cccisd-tooltip';
import notification from 'cccisd-notification';
import style from './style.css';

const Boilerplate = window.cccisd.boilerplate;

const AssignmentLinks = ({ value, row, loadData, settings, isCsv }) => {
    const [list, setList] = useState(null);

    useEffect(() => {
        setList(row[settings.path]);
    });

    const handleClick = (hash, label) => {
        const text = document.createElement('textarea');

        text.value = Boilerplate.url(`/d/${hash}`);

        text.setAttribute('readonly', '');
        text.style = { position: 'absolute', left: '-9999px' };
        document.body.appendChild(text);

        text.select();

        document.execCommand('copy');

        document.body.removeChild(text);

        notification({ message: `Copied ${label} link to clipboard!`, type: 'success' });
    };
    if (isCsv) {
        return (
            <div>
                {row[settings.path].map(
                    item => Boilerplate.url(`/d/${item.currentSession.deployment.hash}`) + ' '
                )}
            </div>
        );
    }
    if (list) {
        return (
            <Modal
                trigger={
                    <button type="button" className="btn btn-primary">
                        <Tooltip
                            title="Show All Assignment Plan Links"
                            size="medium"
                            placement="bottom"
                        >
                            Plan Links
                        </Tooltip>
                    </button>
                }
                size="medium"
                title="Assignment Plan Links"
            >
                {list.map(item => (
                    <div className={style.card}>
                        <h4>{item.label}</h4>
                        <span>{Boilerplate.url(`/d/${item.currentSession.deployment.hash}`)}</span>
                        <button
                            className="btn btn-primary"
                            type="button"
                            onClick={() => {
                                handleClick(item.currentSession.deployment.hash, item.label);
                            }}
                        >
                            Copy to Clipboard
                        </button>
                    </div>
                ))}
            </Modal>
        );
    }
    return '-';
};

export default (settings, callbackHandler) => {
    return props => <AssignmentLinks {...props} settings={settings} />;
};
