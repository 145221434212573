import React from 'react';
import { Field, CccisdInput } from 'cccisd-formik';
import { widgetBuilder, BuilderBlock } from 'cccisd-laravel-appdefs';

export const initialValues = {
    dropdownTitle: 'Youth Experience Survey (YES): Demographics',
    ageBannerTitle: 'Age Breakdown',
    ageChartTitle: 'Age Breakdown',
    GEBannerTitle: 'Gender and Ethnicity',
    genderChartTitle: '"Best Describes" Gender',
    ethnicityChartTitle: '"Best Describes" Race/Ethnicity',
    LSBannerTitle: 'Living Situation',
    situationChartTitle: 'Living Situation',
    membersChartTitle: 'Household Members',
    languageChartTitle: 'Household Language',
    involvementBannerTitle: 'Youth System Involvement',
};

const Component = props => {
    return (
        <div>
            <Field name="dropdownTitle" component={CccisdInput} label="Dropdown Title" />
            <BuilderBlock title="Age">
                <Field name="ageBannerTitle" component={CccisdInput} label="Banner Title" />
                <Field name="ageChartTitle" component={CccisdInput} label="Chart Title" />
            </BuilderBlock>
            <BuilderBlock title="Gender / Ethnicity">
                <Field name="GEBannerTitle" component={CccisdInput} label="Banner Title" />
                <Field name="genderChartTitle" component={CccisdInput} label="Gender Chart Title" />
                <Field
                    name="ethnicityChartTitle"
                    component={CccisdInput}
                    label="Ethnicity Chart Title"
                />
            </BuilderBlock>
            <BuilderBlock title="Living Situation">
                <Field name="LSBannerTitle" component={CccisdInput} label="Banner Title" />
                <Field
                    name="situationChartTitle"
                    component={CccisdInput}
                    label="Situation Chart Title"
                />
                <Field
                    name="membersChartTitle"
                    component={CccisdInput}
                    label="Members Chart Title"
                />
                <Field
                    name="languageChartTitle"
                    component={CccisdInput}
                    label="Language Chart Title"
                />
            </BuilderBlock>
            <BuilderBlock title="System Involvement">
                <Field name="involvementBannerTitle" component={CccisdInput} label="Banner Title" />
            </BuilderBlock>
        </div>
    );
};

// widgetBuilder is a Formik Wrapper.
export default widgetBuilder({ initialValues })(Component);
