import React from 'react';
import ThumbsUp from './images/ThumbsUp.svg';
import ThumbsDown from './images/ThumbsDown.svg';
import style from './style.css';

const Thumbs = ({ descending, bottom }) => {
    return (
        <div className={bottom ? style.thumbSectionBottom : style.thumbSection}>
            {descending ? (
                <>
                    <img src={ThumbsUp} alt="ThumbsUp" />
                    <img src={ThumbsDown} alt="ThumbsDown" />
                </>
            ) : (
                <>
                    <img src={ThumbsDown} alt="ThumbsDown" />
                    <img src={ThumbsUp} alt="ThumbsUp" />
                </>
            )}
        </div>
    );
};

export default Thumbs;
