import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { Page, PrintOnly } from 'cccisd-laravel-report';
import { client } from 'cccisd-apollo';
import { setWidgetsLoaded } from 'js/reducers/report.js';
import antisocialSexQuery from './graphql/antisocialSex.graphql';
import cigaretteAlcoholQuery from './graphql/cigaretteAlcohol.graphql';
import marijuanaOtherQuery from './graphql/marijuanaOther.graphql';
import emotionRelationshipsQuery from './graphql/emotionRelationships.graphql';
import schoolHealthQuery from './graphql/schoolHealth.graphql';
import _isEmpty from 'lodash/isEmpty';
import style from './style.css';
import ChartSection from 'js/components/ChartSection';
import ChartAxis from 'js/components/ChartAxis';
import Thumbs from 'js/components/Thumbs';
import { formatTableSections } from '../../../Player/helpers';
import PrintTable from '../../../components/PrintTable';
// colors:
// dark = #AE4E36
// light = #EFDCD7
// legend = #888888

const antisocialSex = [
    {
        section: 'Antisocial Behavior',
        color: '#EFDCD7',
        barColor: '#AE4E36',
        bars: [
            {
                variable: 'ANTISOC',
                description: 'Delinquency (Past Year)',
                evalTag: 'antisoc',
                tooltip: (
                    <div>
                        <p>
                            Reflects % of respondents reporting at least one antisocial behavior in the last 12 months.
                        </p>
                        <p>
                            Variable ANTISOC = average of 9 variables [YRGUN, YRSOLD, YRSTL, YRAST, YRATT. YR5DOL,
                            YRRUIN, YRTAKE, and YDRUNK] where higher score equates to more antisocial behavior.
                        </p>
                    </div>
                ),
            },
            {
                variable: 'GANGINV',
                description: 'Gang Membership',
                evalTag: 'gang',
                tooltip: (
                    <div>
                        <p>
                            Reflects % of respondents reporting to have ever belonged to a gang and that the gang had a
                            name.
                        </p>
                        <p>Variable GANGINV = affirmative response to either GANG or GANGNM</p>
                    </div>
                ),
            },
        ],
    },
    {
        section: 'Sexual Behavior',
        color: '#EFDCD7',
        barColor: '#AE4E36',
        bars: [
            {
                variable: 'SEX',
                description: 'Had Intercourse',
                evalTag: 'sex',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting to have ever had sexual intercourse</p>
                        <p>
                            Variable SEX (0/1) where 1 = endorsement of prior sexual intercourse (defined as vaginal or
                            anal sex)
                        </p>
                        <p>(Asked of High School Only)</p>
                    </div>
                ),
            },
            {
                variable: 'RISKYSEX',
                description: 'Risky Sexual Behavior (Past Year)',
                evalTag: 'riskysex',
                tooltip: (
                    <div>
                        <p>
                            Reflects % of respondents reporting to have had sex and reporting at least one of the
                            following risky behaviors: 4 or more sex partners; no or inconsistent use of birth control;
                            no or inconsistent condom use.
                        </p>
                        <p>Variable RISKYSEX = sum of SEXNBR, SEXBCn, and SEXCONDn</p>
                        <p>(Asked of High School Only)</p>
                    </div>
                ),
            },
        ],
    },
];

const antisocialSexMS = [
    {
        section: 'Antisocial Behavior',
        color: '#EFDCD7',
        barColor: '#AE4E36',
        bars: [
            {
                variable: 'ANTISOC',
                description: 'Delinquency (Past Year)',
                evalTag: 'antisoc',
                tooltip: (
                    <div>
                        <p>
                            Reflects % of respondents reporting at least one antisocial behavior in the last 12 months.
                        </p>
                        <p>
                            Variable ANTISOC = average of 9 variables [YRGUN, YRSOLD, YRSTL, YRAST, YRATT. YR5DOL,
                            YRRUIN, YRTAKE, and YDRUNK] where higher score equates to more antisocial behavior.
                        </p>
                    </div>
                ),
            },
            {
                variable: 'GANGINV',
                description: 'Gang Membership',
                evalTag: 'gang',
                tooltip: (
                    <div>
                        <p>
                            Reflects % of respondents reporting to have ever belonged to a gang and that the gang had a
                            name.
                        </p>
                        <p>Variable GANGINV = affirmative response to either GANG or GANGNM</p>
                    </div>
                ),
            },
        ],
    },
];

const cigaretteAlcohol = [
    {
        section: 'Substance Use',
        color: '#EFDCD7',
        barColor: '#AE4E36',
        subSections: [
            {
                section: 'Cigarette Use',
                bars: [
                    {
                        variable: 'PYCIG',
                        description: 'Use per Year',
                        evalTag: 'cig1',
                        tooltip: (
                            <div>
                                <p>
                                    Reflects % of respondents reporting to have used cigarettes in the past 12 months.
                                </p>
                                <p>% of respondent scoring (coded value) &gt;1 on PYCIG</p>
                            </div>
                        ),
                    },
                    {
                        variable: 'CIG30DY',
                        description: 'Use per Month',
                        evalTag: 'cig2',
                        tooltip: (
                            <div>
                                <p>Reflects % of respondents reporting to have used cigarettes in the past 30 days.</p>
                                <p>% of respondents scoring &gt;1 on CIG30DY</p>
                            </div>
                        ),
                    },
                    {
                        variable: 'CIG30DY',
                        description: 'Heavy Use ',
                        evalTag: 'cig3',
                        tooltip: (
                            <div>
                                <p>Reflects % of respondents reporting to have used 1 to 5 cigarettes daily.</p>
                                <p>% of respondent scoring (coded value) &gt;3 on CIG30DY</p>
                            </div>
                        ),
                    },
                ],
            },
            {
                section: 'Vape Use',
                bars: [
                    {
                        variable: 'PYVAPE',
                        description: 'Use per Year',
                        evalTag: 'vape1',
                        tooltip: (
                            <div>
                                <p>
                                    Reflects % of respondents reporting to have used e-cigarettes in the past 12 months.
                                </p>
                                <p>% of respondents Response options (coded value) &gt;1 on PYVAPE</p>
                            </div>
                        ),
                    },
                    {
                        variable: 'VAPE30DY',
                        description: 'Use per Month',
                        evalTag: 'vape2',
                        tooltip: (
                            <div>
                                <p>
                                    Reflects % of respondents reporting to have used e-cigarettes in the past 30 days.
                                </p>
                                <p>% of respondents scoring &gt;1 on VAPE30DY</p>
                            </div>
                        ),
                    },
                ],
            },
            {
                section: 'Alcohol Use',
                bars: [
                    {
                        variable: 'PYALC',
                        description: 'Use per Year',
                        evalTag: 'alch1',
                        tooltip: (
                            <div>
                                <p>Reflects % of respondents reporting to have used alcohol in the past 12 months.</p>
                                <p>% of respondents scoring (coded value) &gt;1 on PYALC</p>
                            </div>
                        ),
                    },
                    {
                        variable: 'ALCO30DY',
                        description: 'Use per Month',
                        evalTag: 'alch2',
                        tooltip: (
                            <div>
                                <p>Reflects % of respondents reporting to have used alcohol in the past 30 days.</p>
                                <p>% of respondents scoring &gt;1 on ALCO30DY</p>
                            </div>
                        ),
                    },
                    {
                        variable: 'ALCBINGE',
                        description: 'Binge Drinking',
                        evalTag: 'alch3',
                        tooltip: (
                            <div>
                                <p>
                                    Reflects % of respondents reporting to have had 5 or more drinks at one time in the
                                    past 2 weeks.
                                </p>
                                <p>% of respondents scoring &gt;1 on ALCBINGE</p>
                            </div>
                        ),
                    },
                ],
            },
        ],
    },
];
const marijuanaOther = [
    {
        section: 'Substance Use',
        color: '#EFDCD7',
        barColor: '#AE4E36',
        subSections: [
            {
                section: 'Marijuana Use',
                bars: [
                    {
                        variable: 'PYMAR',
                        description: 'Use per Year',
                        evalTag: 'mj1',
                        tooltip: (
                            <div>
                                <p>Reflects % of respondents reporting to have use marijuana in the past 12 months.</p>
                                <p>% of respondents scoring (coded value) &gt;1 on PYMAR</p>
                            </div>
                        ),
                    },
                    {
                        variable: 'MARI30DY',
                        description: 'Use per Month',
                        evalTag: 'mj2',
                        tooltip: (
                            <div>
                                <p>
                                    Reflects % of respondents reporting to have had 5 or more drinks at one time in the
                                    past 2 weeks.
                                </p>
                                <p>% of respondents scoring &gt;1 on MARI30DY</p>
                            </div>
                        ),
                    },
                ],
            },
            {
                section: 'Prescription Drug Use',
                bars: [
                    {
                        variable: 'PYPRES',
                        description: 'Use per Year',
                        evalTag: 'prescription1',
                        tooltip: (
                            <div>
                                <p>
                                    Reflects % of respondents reporting to have use prescription drugs in the past 12
                                    months.
                                </p>
                                <p>% of respondents scoring (coded value) &gt;1 on PYPRES</p>
                            </div>
                        ),
                    },
                    {
                        variable: 'PRES30DY',
                        description: 'Use per Month',
                        evalTag: 'prescription2',
                        tooltip: (
                            <div>
                                <p>
                                    Reflects % of respondents reporting to have use prescription drugs in the past 30
                                    days.
                                </p>
                                <p>% of respondents scoring (coded value) &gt;1 on PRES30DY</p>
                            </div>
                        ),
                    },
                ],
            },
            {
                section: 'Other Drug Use',
                bars: [
                    {
                        variable: 'PYLSD',
                        description: 'Use per Year',
                        evalTag: 'otherDrug1',
                        tooltip: (
                            <div>
                                <p>
                                    Reflects % of respondents reporting to have use prescription drugs in the past 12
                                    months.
                                </p>
                                <p>% of respondents scoring (coded value) &gt;1 on PYLSD</p>
                            </div>
                        ),
                    },
                    {
                        variable: 'LSD30DY',
                        description: 'Use per Month',
                        evalTag: 'otherDrug2',
                        tooltip: (
                            <div>
                                <p>
                                    Reflects % of respondents reporting to have use prescription drugs in the past 30
                                    days.
                                </p>
                                <p>% of respondents scoring (coded value) &gt;1 on LSD30DY</p>
                            </div>
                        ),
                    },
                ],
            },
        ],
    },
];

const emotionRelationships = [
    {
        section: 'Emotional Wellbeing',
        color: '#EFDCD7',
        barColor: '#AE4E36',
        bars: [
            {
                variable: 'DEPSX',
                description: 'Symptoms of Depression',
                evalTag: 'depression',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting to have had symptoms of depression in the last year.</p>
                        <p>
                            DEPSX = average of LIFEBAD, NOGOOD, FAILURE, & DEPRES where higher score = more depressive
                            symptoms
                        </p>
                        <p>(Asked of High School Only)</p>
                    </div>
                ),
            },
            {
                variable: 'ANXSX',
                description: 'Symptoms of Anxiety (Past Year)',
                evalTag: 'anxiety',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting to have had symptoms of anxiety in the past year.</p>
                        <p>
                            DEPSX = average of ISICK, IWORRY, IUNHAPPY, INERVOUS, & IFEARS where higher score = more
                            anxiety symptoms
                        </p>
                    </div>
                ),
            },
            {
                variable: 'SUICTHGT',
                description: 'Suicidal Thoughts',
                evalTag: 'suicide',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting to have had suicidal thoughts in the past year.</p>
                        <p>1 variable [SUICTHGT] where higher score = suicidal thoughts</p>
                    </div>
                ),
            },
            {
                variable: 'EMOREG',
                description: 'Poor Coping Strategies (Past Year)',
                evalTag: 'emoreg',
                tooltip: (
                    <div>
                        <p>
                            Reflects % of respondents reporting to have had indication of low emotional regulation in
                            the past year.
                        </p>
                    </div>
                ),
            },
        ],
    },
];
const emotionRelationshipsMS = [
    {
        section: 'Emotional Wellbeing',
        color: '#EFDCD7',
        barColor: '#AE4E36',
        bars: [
            {
                variable: 'ANXSX',
                description: 'Symptoms of Anxiety (Past Year)',
                evalTag: 'anxiety',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting to have had symptoms of anxiety in the past year.</p>
                        <p>
                            DEPSX = average of ISICK, IWORRY, IUNHAPPY, INERVOUS, & IFEARS where higher score = more
                            anxiety symptoms
                        </p>
                    </div>
                ),
            },
            {
                variable: 'SUICTHGT',
                description: 'Considered Suicide (Past Year)',
                evalTag: 'suicide',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting to have had suicidal thoughts in the past year.</p>
                        <p>1 variable [SUICTHGT] where higher score = suicidal thoughts</p>
                    </div>
                ),
            },
            {
                variable: 'EMOREG',
                description: 'Low Emotional Regulation (Past Year)',
                evalTag: 'emoreg',
                tooltip: (
                    <div>
                        <p>
                            Reflects % of respondents reporting to have had indication of low emotional regulation in
                            the past year.
                        </p>
                    </div>
                ),
            },
        ],
    },
];
const schoolHealth = [
    {
        section: 'Education',
        color: '#EFDCD7',
        barColor: '#AE4E36',
        bars: [
            {
                variable: 'GRADES',
                description: 'Mostly Failing (Past Year)',
                evalTag: 'grades',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting mostly failing grades (Ds and Fs) in the past year.</p>
                        <p>Variable = GRADES</p>
                    </div>
                ),
            },
            {
                variable: 'PYEXPELL',
                description: 'Suspended or Expelled (Past Year)',
                evalTag: 'expelled',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents that report being suspended or expelled in the past year.</p>
                        <p>Variable = PYEXPELL</p>
                    </div>
                ),
            },
        ],
    },
    {
        section: 'Physical Health',
        color: '#EFDCD7',
        barColor: '#AE4E36',
        bars: [
            {
                variable: 'MISSI',
                description: '4+ Sick Days (Past Month)',
                evalTag: 'missed',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents reporting 4 or more sick days in the past 30 days.</p>
                        <p>Variable = MISSI</p>
                    </div>
                ),
            },
            {
                variable: 'HLTHADHD',
                description: 'Diagnosed ADD/ADHD',
                evalTag: 'adhd',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents that report being diagnosed with ADD/ADHD.</p>
                        <p>Variable = HLTHADHD</p>
                    </div>
                ),
            },
            {
                variable: 'CHRHEAPROB',
                description: 'Asthma/Diabetes',
                evalTag: 'chronic',
                tooltip: (
                    <div>
                        <p>Reflects % of respondents that report being diagnosed with asthma and/or diabetes.</p>
                        <p>
                            CHRHEAPROB = average of HLTHASTH and HLTHDIAB where a higher score equals more chronic
                            health problems
                        </p>
                    </div>
                ),
            },
        ],
    },
];

const getVariables = array => {
    let variables = [];
    array.forEach(section => {
        if (section.subSections) {
            section.subSections.forEach(sub => {
                sub.bars.forEach(item => {
                    variables.push(item.variable);
                });
            });
        } else {
            section.bars.forEach(item => {
                variables.push(item.variable);
            });
        }
    });

    return variables;
};

const types = {
    antisocialSex: {
        query: antisocialSexQuery,
        variables: getVariables(antisocialSex),
    },
    cigaretteAlcohol: {
        query: cigaretteAlcoholQuery,
        variables: getVariables(cigaretteAlcohol),
    },
    marijuanaOther: {
        query: marijuanaOtherQuery,
        variables: getVariables(marijuanaOther),
    },
    emotionRelationships: {
        query: emotionRelationshipsQuery,
        variables: getVariables(emotionRelationships),
    },
    schoolHealth: {
        query: schoolHealthQuery,
        variables: getVariables(schoolHealth),
    },
};

const Outcomes = props => {
    // const { title, description } = props.settings;
    const [antisocData, setAntisocialData] = useState(null);
    const [substanceData, setSubstanceData] = useState(null);
    const [otherSubData, setOtherSubstanceData] = useState(null);
    const [emotionalData, setEmotionalData] = useState(null);
    const [schoolData, setSchoolData] = useState(null);
    const isMSOnly = props.filters.level === 'middle_school';
    useEffect(() => {
        (async () => {
            const antisoc = await queryData(props.assignmentProgressIds, 'antisocialSex');
            setAntisocialData(antisoc);

            const substance = await queryData(props.assignmentProgressIds, 'cigaretteAlcohol');
            setSubstanceData(substance);

            const otherSubstance = await queryData(props.assignmentProgressIds, 'marijuanaOther');
            setOtherSubstanceData(otherSubstance);

            const emotional = await queryData(props.assignmentProgressIds, 'emotionRelationships');
            setEmotionalData(emotional);

            const school = await queryData(props.assignmentProgressIds, 'schoolHealth');
            setSchoolData(school);

            props.setWidgetsLoaded('outcomes');
        })();
    }, [props.assignmentProgressIds]);

    const queryData = async (idList, type) => {
        const query = types[type].query;
        const variableList = types[type].variables;

        try {
            const response = await client.query({
                query,
                variables: {
                    idList,
                    variableList,
                },
            });

            if (response && response.data && !_isEmpty(response.data.flows.assignmentProgressSummary)) {
                return response.data.flows.assignmentProgressSummary;
            }
        } catch (e) {
            console.error(e);
            return null;
        }
    };
    const combineSubstances = (section1, section2) => {
        let combinedSubSections = [];
        const combinedSections = section1.concat(section2);

        combinedSections.forEach(section => {
            combinedSubSections = combinedSubSections.concat(section.subSections);
        });

        return [
            {
                section: section1[0].section,
                color: section1[0].color,
                barColor: section1[0].barColor,
                subSections: combinedSubSections,
            },
        ];
    };

    const sections = isMSOnly
        ? [antisocialSexMS, cigaretteAlcohol, marijuanaOther, emotionRelationshipsMS, schoolHealth]
        : [antisocialSex, cigaretteAlcohol, marijuanaOther, emotionRelationships, schoolHealth];

    const tableData = formatTableSections(
        sections,
        [antisocData, substanceData, otherSubData, emotionalData, schoolData],
        [
            'Antisocial Behavior',
            'Sexual Behavior',
            'Substance Use',
            'Emotional Wellbeing',
            'Education',
            'Physical Health',
        ]
    );
    return (
        <>
            <div className={style.barChartWrapper}>
                <Page>
                    <Thumbs descending />
                    <ChartAxis type="top" />
                    {antisocData ? (
                        <ChartSection sections={isMSOnly ? antisocialSexMS : antisocialSex} data={antisocData} />
                    ) : (
                        <div style={{ width: '99%', margin: 'auto' }}>
                            <Skeleton height={275} width="100%" style={{ marginBottom: '2em', marginTop: '20px' }} />
                            <Skeleton height={275} width="100%" style={{ marginBottom: '2em' }} />
                        </div>
                    )}
                </Page>
                <Page>
                    <PrintOnly>
                        <Thumbs descending />
                        <ChartAxis type="top" />
                    </PrintOnly>
                    {substanceData && otherSubData ? (
                        <ChartSection
                            sections={combineSubstances(cigaretteAlcohol, marijuanaOther)}
                            data={{ ...substanceData, ...otherSubData }}
                        />
                    ) : (
                        <div style={{ width: '99%', margin: 'auto' }}>
                            <Skeleton height={825} width="100%" style={{ marginBottom: '2em', marginTop: '20px' }} />
                        </div>
                    )}
                </Page>
                <Page>
                    <PrintOnly>
                        <Thumbs descending />
                        <ChartAxis type="top" />
                    </PrintOnly>
                    {emotionalData ? (
                        <ChartSection
                            sections={isMSOnly ? emotionRelationshipsMS : emotionRelationships}
                            data={emotionalData}
                        />
                    ) : (
                        <div style={{ width: '99%', margin: 'auto' }}>
                            <Skeleton height={300} width="100%" style={{ marginBottom: '2em', marginTop: '20px' }} />
                            <Skeleton height={275} width="100%" style={{ marginBottom: '2em' }} />
                        </div>
                    )}
                </Page>
                <Page>
                    <PrintOnly>
                        <Thumbs descending />
                        <ChartAxis type="top" />
                    </PrintOnly>
                    {schoolData ? (
                        <ChartSection sections={schoolHealth} data={schoolData} />
                    ) : (
                        <div style={{ width: '99%', margin: 'auto' }}>
                            <Skeleton height={275} width="100%" style={{ marginBottom: '2em', marginTop: '20px' }} />
                            <Skeleton height={275} width="100%" style={{ marginBottom: '2em' }} />
                        </div>
                    )}
                </Page>
            </div>
            <PrintOnly>
                <Page>
                    <PrintTable
                        {...tableData}
                        title="Negative Outcomes"
                        descending
                        tableHeaders={[
                            'Category',
                            'Item',
                            '# of Responses',
                            '# of Negative Responses',
                            '% of Negative Responses',
                        ]}
                    />
                </Page>
            </PrintOnly>
        </>
    );
};

Outcomes.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    // redux
    assignmentProgressIds: PropTypes.array,
    setWidgetsLoaded: PropTypes.func,
};

// Filters are passed from reportTemplatePlayer HOC to the Report Player and Widgets
Outcomes.defaultProps = {
    filters: {},
};

const mapStateToProps = state => ({
    assignmentProgressIds: state.app.report.assignmentProgressIds,
});

export default connect(mapStateToProps, { setWidgetsLoaded })(Outcomes);
